import { useNavigate, useParams } from "react-router-dom";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroBreadCrumbs from "../../components/shared/CiroBreadCrumbs";
import classNames from "classnames";
import CiroTitle from "../../components/shared/CiroTitle";
import CiroButton, {
  CiroButtonStyleEnum,
} from "../../components/shared/CiroButton";
import MobileNumbersWorkflowForm from "../../components/mobileNumbersWorkflow/MobileNumbersWorkflowForm";

const MobileNumbersWorkflow = () => {
  const { workflowId } = useParams<{ workflowId: string }>();
  const navigate = useNavigate();

  return (
    <CiroContainer className={classNames("ciro-v1-bg-zinc-100")}>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-flex-row",
          "ciro-v1-justify-between",
          "ciro-v1-w-full",
          "ciro-v1-items-center",
        )}
      >
        <CiroBreadCrumbs
          values={["Enrich CRM", `${workflowId ? "Edit" : "Create"} workflow`]}
          href="/enrich-crm/workflows"
        />
      </div>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-w-full",
          "ciro-v1-flex-row",
          "ciro-v1-items-center",
          "ciro-v1-justify-between",
        )}
      >
        <CiroTitle title={workflowId ? "Edit workflow" : "Create workflow"} />
        <CiroButton
          style={CiroButtonStyleEnum.INVERTED_LOUD}
          analyticsField="cancel-workflow-changes"
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancel
        </CiroButton>
      </div>
      <div
        className={classNames(
          "ciro-v1-w-full",
          "ciro-v1-border-t",
          "ciro-v1-border-gray-300",
          "ciro-v1-my-4",
        )}
      />
      <MobileNumbersWorkflowForm workflowId={workflowId} />
    </CiroContainer>
  );
};

export default MobileNumbersWorkflow;
