import {
  useEffect,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
  useMemo,
} from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import debounce from "lodash/debounce";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import toast from "react-hot-toast";
import {
  DispositionSettingsForm_GetCrmDispositionsQuery,
  DispositionSettingsForm_GetCrmDispositionsQueryVariables,
  CrmMobileConnection_UserAccountQuery,
  OrgContactObjectType,
  UpdateOrganizationMergeIntegrationInput,
} from "../../../__generated__/graphql";
import CiroDropDown from "../../shared/CiroDropdown";
import CiroTextInput from "../../shared/CiroTextInput";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import classNames from "classnames";

interface DropdownOption {
  value: string;
  label: string;
}
interface DispositionSettingsFormHandle {
  onSubmit: () => void;
}

const DispositionSettingsForm_GetCrmDispositions = gql`
  query DispositionSettingsForm_GetCrmDispositions($field: String) {
    organizationMergeIntegrationCrmDispositions(field: $field) {
      id
      label
    }
  }
`;

const DispositionSettingsForm_UpdateOrganizationMergeIntegration = gql`
  mutation DispositionSettingsForm_UpdateOrganizationMergeIntegration(
    $input: UpdateOrganizationMergeIntegrationInput!
  ) {
    updateOrganizationMergeIntegration(input: $input) {
      id
    }
  }
`;

interface DispositionSettingsFormProps {
  initialData: CrmMobileConnection_UserAccountQuery["userAccount"];
  refetch: () => void;
  setCrmObjectType: (crmObjectType: OrgContactObjectType) => void;
  isInitialSetup: boolean;
}

const DispositionSettingsForm = forwardRef<
  DispositionSettingsFormHandle,
  DispositionSettingsFormProps
>(
  (
    {
      initialData,
      refetch,
      setCrmObjectType,
      isInitialSetup,
    }: DispositionSettingsFormProps,
    ref: React.ForwardedRef<DispositionSettingsFormHandle>,
  ) => {

    const [dispositionError, setDispositionError] = useState(false);

    const DispositionSettingsUpdateFormSchema = yup.object({
      disposition_field: yup.string().when("$integrationName", {
        is: "Salesforce",
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      custom_disposition_field: yup
        .string()
        .when(["$integrationName", "disposition_field"], {
          is: (integrationName: string, dispositionField: string) =>
            integrationName === "Salesforce" && dispositionField === "Custom",
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
      bad_number_dispositions: yup
        .array()
        .of(yup.string())
        .min(1, "At least one option is required"),
      good_number_dispositions: yup
        .array()
        .of(yup.string())
        .min(1, "At least one option is required"),
    });

    const {
      control,
      formState: { errors },
      getValues,
      setValue,
      handleSubmit,
      trigger,
    } = useForm({
      resolver: yupResolver(DispositionSettingsUpdateFormSchema),
      defaultValues: {
        disposition_field:
          initialData?.org?.organizationMergeIntegration
            ?.phone_number_status_field || "",
        custom_disposition_field: "",
        bad_number_dispositions:
          initialData?.org?.organizationMergeIntegration
            ?.invalid_number_statuses || [],
        good_number_dispositions:
          initialData?.org?.organizationMergeIntegration
            ?.valid_numbers_statuses || [],
      },
      context: {
        integrationName:
          initialData?.org?.organizationMergeIntegration?.integration || "",
      },
    });

    const [
      dispositionField,
      customDispositionField,
      badNumberDispositions,
      goodNumberDispositions,
    ] = useWatch({
      control,
      name: [
        "disposition_field",
        "custom_disposition_field",
        "bad_number_dispositions",
        "good_number_dispositions",
      ],
    });

    const [getDispositions, { loading: dispositionsLoading }] = useLazyQuery<
      DispositionSettingsForm_GetCrmDispositionsQuery,
      DispositionSettingsForm_GetCrmDispositionsQueryVariables
    >(DispositionSettingsForm_GetCrmDispositions, {
      onCompleted: (data) => {
        const fetchedDispositions =
          data?.organizationMergeIntegrationCrmDispositions || [];
        const parsedDispositions = fetchedDispositions.map((disposition) => ({
          label: disposition.label,
          value: disposition.id,
        }));
        setDispositions(parsedDispositions);
        setDispositionError(
          fetchedDispositions.length === 0 &&
            getValues("disposition_field") !== "Custom",
        );
      },
    });

    const [dispositions, setDispositions] = useState<DropdownOption[]>([]);
    const integrationName =
      initialData?.org?.organizationMergeIntegration?.integration || "";

    useEffect(() => {
      if (
        initialData?.org?.organizationMergeIntegration?.integration ===
        "Salesforce"
      ) {
        const phoneField =
          initialData?.org?.organizationMergeIntegration
            ?.phone_number_status_field || "";
        if (phoneField) {
          if (phoneField !== "Custom") {
            getDispositions({ variables: { field: phoneField } });
          }
        }
      } else if (
        initialData?.org?.organizationMergeIntegration?.integration ===
        "HubSpot"
      ) {
        getDispositions();
      }
    }, [initialData, getDispositions]);

    const getDispositionsCallback = useCallback(
      (field: string) => {
        getDispositions({ variables: { field } });
      },
      [getDispositions],
    );

    const debouncedGetDispositions = useMemo(
      () => debounce(getDispositionsCallback, 1000),
      [getDispositionsCallback],
    );

    useEffect(() => {
      return () => {
        debouncedGetDispositions.cancel();
      };
    }, [debouncedGetDispositions]);

    const checkValidity = async () => {
      const valid = await trigger();
      return valid;
    };

    const onSubmit = handleSubmit(async (data) => {
      try {
        const input: UpdateOrganizationMergeIntegrationInput = {
          invalid_number_statuses: data.bad_number_dispositions,
          valid_numbers_statuses: data.good_number_dispositions,
          phone_number_status_field:
            data.disposition_field === "Custom"
              ? data.custom_disposition_field
              : data.disposition_field,
        };

        const result = await updateOrganizationMergeIntegration({
          variables: { input },
        });

        if (result.data) {
          refetch();
          toast.success("Settings saved successfully");
        }
      } catch (error) {
        console.error("Error updating CRM settings:", error);
        toast.error(
          "An error occurred while saving settings. Please try again.",
        );
      }
    });

    useImperativeHandle(ref, () => ({
      onSubmit,
      checkValidity,
    }));

    const [updateOrganizationMergeIntegration, { loading: updateLoading }] =
      useMutation(DispositionSettingsForm_UpdateOrganizationMergeIntegration);

    return (
      <form>
        {integrationName === "Salesforce" && (
          <>
            <div className={classNames("ciro-v1-pt-4")}>
              <CiroDropDown
                creatable={false}
                async={false}
                label="Which field contains data on whether the call was successful"
                showSpinner={Boolean(dispositionsLoading)}
                options={[
                  {
                    label: "Standard Disposition Field (Call Disposition)",
                    value: "CallDisposition",
                  },
                  {
                    label: "RingDNA Disposition Field",
                    value: "ringdna__Call_Disposition__c",
                  },
                  {
                    label: "Aircall Disposition Field",
                    value: "aircall__Connection_status__c",
                  },
                  { label: "Custom Field", value: "Custom" },
                ]}
                value={dispositionField}
                onChange={async (v) => {
                  setValue("disposition_field", v as string);
                  setValue("custom_disposition_field", "");
                  setValue("bad_number_dispositions", []);
                  setValue("good_number_dispositions", []);
                  setDispositions([]);
                  if (v !== "Custom") {
                    getDispositions({ variables: { field: v as string } });
                  }
                }}
                error={
                  dispositionError && dispositionField !== "Custom"
                    ? "No dispositions found for selected field"
                    : (errors.disposition_field?.message as string)
                }
              />
            </div>
            {dispositionField === "Custom" && (
              <div className={classNames("ciro-v1-pt-4", "ciro-v1-relative")}>
                <CiroTextInput
                  label="Custom Disposition Field"
                  value={customDispositionField}
                  error={
                    dispositionError
                      ? "No dispositions found for this field"
                      : errors.custom_disposition_field?.message
                  }
                  onChange={(v) => {
                    setValue("custom_disposition_field", v.target.value);
                    debouncedGetDispositions(v.target.value);
                  }}
                />
              </div>
            )}
          </>
        )}
        <div className={classNames("ciro-v1-pt-4")}>
          <CiroDropDown
            isDisabled={dispositions.length === 0}
            isMulti={true}
            label="Which value(s) indicate whether the call went to an invalid number, e.g. “Wrong number” or “Out of service”"
            options={dispositions}
            value={badNumberDispositions}
            onChange={(v) => {
              setValue("bad_number_dispositions", v as string[]);
            }}
            error={errors.bad_number_dispositions?.message as string}
          />
        </div>
        <div className={classNames("ciro-v1-pt-4")}>
          <CiroDropDown
            isDisabled={dispositions.length === 0}
            isMulti={true}
            label="Which value(s) for the field indicate whether the number was successfully reached, e.g. “Answered”"
            options={dispositions}
            value={goodNumberDispositions}
            onChange={(v) => {
              setValue("good_number_dispositions", v as string[]);
            }}
            error={errors.good_number_dispositions?.message as string}
          />
        </div>
        {!isInitialSetup && (
          <div
            className={classNames(
              "ciro-v1-pt-6",
              "ciro-v1-flex",
              "ciro-v1-justify-end",
            )}
          >
            <CiroButton
              analyticsField="save_crm_integration_changes"
              disabled={updateLoading}
              style={CiroButtonStyleEnum.LOUD}
              onClick={onSubmit}
            >
              Save changes
            </CiroButton>
          </div>
        )}
      </form>
    );
  },
);

export default DispositionSettingsForm;
