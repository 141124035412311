import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import CiroModal from "../shared/CiroModal";
import XIcon from "../../assets/img/icons/XIcon";
import CiroDatePicker from "../shared/CiroDatePicker";
import SkeletonLoading from "../shared/SkeletonLoading";
import { gql, useMutation, useQuery } from "@apollo/client";
import { format, subWeeks } from "date-fns";
import {
  MobileNumbersRunModal_ContactsToEnrichQuery,
  MobileNumbersRunModal_ContactsToEnrichQueryVariables,
  MobileNumbersRunModal_CreatePhoneNumbersRequestTransactionMutation,
  MobileNumbersRunModal_CreatePhoneNumbersRequestTransactionMutationVariables,
  MobileNumbersRunModal_WorkflowsQuery,
  MobileNumbersRunModal_WorkflowsQueryVariables,
  MobileNumbersRunPreviewSelection_PhoneNumberRequestsFragmentDoc,
  PhoneNumberRequestSegmentTypeEnum,
} from "../../__generated__/graphql";
import { MobileNumbersTransactionsTable_PhoneNumbeRequestTransactions } from "./MobileNumbersTable/MobileNumbersTransactionsTable";
import CiroDropdown from "../shared/CiroDropdown";
import pluralize from "pluralize";
import MobileNumbersRunPreviewSelection, {
  MobileNumbersRunPreviewSelection_PhoneNumberRequests,
} from "./MobileNumbersRunPreviewSelection";
import { useFragment } from "../../__generated__";
import CiroSwitch from "../shared/CiroSwitch";

const MobileNumbersRunModal_ContactsToEnrich = gql`
  query MobileNumbersRunModal_ContactsToEnrich($after: Date, $workflowId: Int) {
    newPhoneNumberRequests(after: $after, workflowId: $workflowId) {
      creditsPerRequest
      phoneNumberRequests {
        org_phone_call_id
        orgContact {
          first_name
          last_name
          linkedin_id
          external_object_type
          external_id
          company_name
          source
          orgPhoneNumbers {
            phone_number
            external_phone_field
            status
            external_status
          }
          lastEnrichedByCiroAt
        }
      }
      ...MobileNumbersRunPreviewSelection_PhoneNumberRequests
      creditsNeeded
    }
  }
  ${MobileNumbersRunPreviewSelection_PhoneNumberRequests}
`;

const MobileNumbersRunModal_Workflows = gql`
  query MobileNumbersRunModal_Workflows {
    organization {
      organizationMergeIntegration {
        org_contact_object_type
      }
      phoneNumberRequestWorkflows {
        id
        name
        segmentationType
      }
      credit_plan {
        balance
      }
    }
  }
`;

const MobileNumbersRunModal_CreatePhoneNumbersRequestTransaction = gql`
  mutation MobileNumbersRunModal_CreatePhoneNumbersRequestTransaction(
    $phoneNumberRequests: [PhoneNumberRequestInput!]!
  ) {
    createNewPhoneNumbersRequestTransaction(
      phoneNumberRequests: $phoneNumberRequests
    ) {
      id
    }
  }
`;

interface IMobileNumbersRunModalProps {
  isOpen: boolean;
  onClose: () => void;
  startingWorkflowId?: number | null;
}

const DEFAULT_LOOK_BACK_PERIOD = subWeeks(new Date(), 2);

const MobileNumbersRunModal = ({
  isOpen,
  onClose,
  startingWorkflowId = null,
}: IMobileNumbersRunModalProps) => {
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<number | null>(
    startingWorkflowId,
  );
  const [segmentationType, setSegmentationType] =
    useState<PhoneNumberRequestSegmentTypeEnum | null>(null);

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    DEFAULT_LOOK_BACK_PERIOD,
  );

  const [includePreviouslyEnriched, setIncludePreviouslyEnriched] = useState(false);

  useEffect(() => {
    setSelectedWorkflowId(startingWorkflowId);
  }, [startingWorkflowId]);

  const { data: contactsToEnrichData, loading: contactsToEnrichLoading } =
    useQuery<
      MobileNumbersRunModal_ContactsToEnrichQuery,
      MobileNumbersRunModal_ContactsToEnrichQueryVariables
    >(MobileNumbersRunModal_ContactsToEnrich, {
      skip: !isOpen || selectedWorkflowId == null || !selectedDate,
      fetchPolicy: "network-only",
      variables: {
        after: selectedDate ? format(selectedDate, "yyyy-MM-dd") : null,
        workflowId: selectedWorkflowId,
      },
    });

  const [selectedContactIds, setSelectedContactIds] = useState<Set<string>>(
    new Set(),
  );

  useEffect(() => {
    setSelectedContactIds(
      new Set(
        contactsToEnrichData?.newPhoneNumberRequests?.phoneNumberRequests.filter(
          (request) =>
            includePreviouslyEnriched || !request.orgContact.lastEnrichedByCiroAt,
          )
          .map((request) => request.orgContact.external_id),
      ),
    );
  }, [contactsToEnrichData, includePreviouslyEnriched]);

  const { data: workflowsData, loading: workflowsLoading } = useQuery<
    MobileNumbersRunModal_WorkflowsQuery,
    MobileNumbersRunModal_WorkflowsQueryVariables
  >(MobileNumbersRunModal_Workflows);

  const workflowIdMap = useMemo(() => {
    if (!workflowsData?.organization?.phoneNumberRequestWorkflows) {
      return {};
    }
    return workflowsData.organization.phoneNumberRequestWorkflows.reduce(
      (acc, workflow) => {
        acc[workflow.id] = {
          id: workflow.id,
          value: workflow.name,
          segmentationType: workflow.segmentationType,
        };
        return acc;
      },
      {} as Record<
        number,
        {
          id: number;
          value: string;
          segmentationType: PhoneNumberRequestSegmentTypeEnum;
        }
      >,
    );
  }, [workflowsData]);

  const workflowOptions = useMemo(() => {
    if (workflowsData?.organization?.phoneNumberRequestWorkflows) {
      return workflowsData.organization?.phoneNumberRequestWorkflows.map(
        (workflow) => ({
          label: workflow.name,
          value: workflow.id,
        }),
      );
    }
    return [];
  }, [workflowsData]);

  const callsOrContacts = useMemo(() => {
    if (
      segmentationType === PhoneNumberRequestSegmentTypeEnum.CallDisposition
    ) {
      return "calls";
    } else if (
      workflowsData?.organization?.organizationMergeIntegration
        ?.org_contact_object_type
    ) {
      return pluralize(
        workflowsData.organization.organizationMergeIntegration
          .org_contact_object_type,
      ).toLowerCase();
    }

    return "contacts";
  }, [segmentationType, workflowsData]);

  const [
    createPhoneNumbersRequestTransaction,
    {
      loading: createPhoneNumbersRequestTransactionLoading,
      error: createPhoneNumbersRequestTransactionError,
    },
  ] = useMutation<
    MobileNumbersRunModal_CreatePhoneNumbersRequestTransactionMutation,
    MobileNumbersRunModal_CreatePhoneNumbersRequestTransactionMutationVariables
  >(MobileNumbersRunModal_CreatePhoneNumbersRequestTransaction);

  useEffect(() => {
    if (!isOpen) {
      setSelectedDate(DEFAULT_LOOK_BACK_PERIOD);
    }
  }, [isOpen]);

  const contactsToEnrich =
    contactsToEnrichData?.newPhoneNumberRequests?.phoneNumberRequests.filter(
      (request) =>
        includePreviouslyEnriched || !request.orgContact.lastEnrichedByCiroAt
    ) || [];

  const creditsNeeded =
    (contactsToEnrichData?.newPhoneNumberRequests?.creditsPerRequest || 0) *
    selectedContactIds.size;
  const creditsAvailable =
    workflowsData?.organization?.credit_plan?.balance || 0;

  const tablePhoneNumberRequests = useFragment(
    MobileNumbersRunPreviewSelection_PhoneNumberRequestsFragmentDoc,
    contactsToEnrichData?.newPhoneNumberRequests,
  );

  let errorMsg = null;
  if (createPhoneNumbersRequestTransactionError) {
    errorMsg = createPhoneNumbersRequestTransactionError.message;
  } else if (creditsAvailable < creditsNeeded) {
    errorMsg = "You do not have enough credits to run this enrichment";
  }

  return (
    <>
      <CiroModal isOpen={isOpen} onClose={onClose} size="LARGE">
        <div className={classNames("ciro-v1-flex", "ciro-v1-justify-between")}>
          {workflowsLoading && (
            <SkeletonLoading numSkeletons={1} skeletonHeight={"20px"} />
          )}
          {!workflowsLoading && (
            <div
              className={classNames(
                "ciro-v1-text-lg",
                "ciro-v1-font-semibold",
                "ciro-v1-pb-2",
              )}
            >
              {startingWorkflowId
                ? `Run workflow: ${
                    workflowOptions.find(
                      (option) => option.value === startingWorkflowId,
                    )?.label
                  }`
                : "Run now"}
            </div>
          )}
          <CiroButton
            style={CiroButtonStyleEnum.UNSTYLED}
            analyticsField="Close Run Mobile Numbers modal"
            onClick={onClose}
          >
            <XIcon />
          </CiroButton>
        </div>
        <div className={classNames("ciro-v1-min-h-[5rem]", "ciro-v1-max-h-[80vh]")}>
          <div className={classNames("ciro-v1-mb-4")}>
            {!startingWorkflowId && (
              <CiroDropdown
                isMulti={false}
                label={"Workflow"}
                options={workflowOptions}
                value={selectedWorkflowId}
                onChange={(value) => {
                  setSelectedWorkflowId(value);
                  setSegmentationType(workflowIdMap[value].segmentationType);
                }}
                maxMenuHeight={100}
                isDisabled={Boolean(startingWorkflowId)}
              />
            )}
          </div>
          <div>
            <div className={classNames("ciro-v1-pb-2")}>
              Run for {callsOrContacts} updated after
            </div>
            <CiroDatePicker
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              maxDate={new Date()}
              placeholderText="Select Date"
            />
          </div>
          <div className={classNames("ciro-v1-py-4")}>
            <div className={classNames("ciro-v1-pb-2")}>
              <div className={classNames("ciro-v1-pb-2")}>
                Run over previously enriched contacts
              </div>
              <CiroSwitch
                checked={includePreviouslyEnriched}
                onChange={(checked) => setIncludePreviouslyEnriched(checked)}
              />
            </div>
            {contactsToEnrichLoading && (
              <SkeletonLoading
                numSkeletons={1}
                skeletonHeight={"20px"}
                skeletonZIndex={0}
              />
            )}

            {!contactsToEnrichLoading &&
              selectedWorkflowId &&
              tablePhoneNumberRequests && (
                <MobileNumbersRunPreviewSelection
                  creditsNeeded={creditsNeeded}
                  phoneNumberRequests={tablePhoneNumberRequests}
                  selectedContactIds={selectedContactIds}
                  setSelectedContactIds={setSelectedContactIds}
                  includePreviouslyEnriched={includePreviouslyEnriched}
                />
              )}
          </div>
          <CiroButton
            analyticsField="Run Mobile Numbers"
            style={CiroButtonStyleEnum.LOUD}
            onClick={() => {
              createPhoneNumbersRequestTransaction({
                variables: {
                  phoneNumberRequests: contactsToEnrich
                    .filter((contact) =>
                      selectedContactIds.has(contact.orgContact.external_id),
                    )
                    .map((contact) => ({
                      org_phone_call_id: contact.org_phone_call_id,
                      org_contact: {
                        external_object_type:
                          contact.orgContact.external_object_type,
                        external_id: contact.orgContact.external_id,
                        linkedin_id: contact.orgContact.linkedin_id,
                        first_name: contact.orgContact.first_name,
                        last_name: contact.orgContact.last_name,
                        company_name: contact.orgContact.company_name,
                        source: contact.orgContact.source,
                        org_phone_numbers:
                          contact.orgContact.orgPhoneNumbers.map(
                            (phoneNumber) => ({
                              phone_number: phoneNumber.phone_number,
                              external_phone_field:
                                phoneNumber.external_phone_field,
                              external_status: phoneNumber.external_status,
                            }),
                          ),
                      },
                    })),
                },
                onCompleted(data) {
                  if (data.createNewPhoneNumbersRequestTransaction.id) {
                    onClose();
                  }
                },
                refetchQueries: [
                  MobileNumbersTransactionsTable_PhoneNumbeRequestTransactions,
                ],
              });
            }}
            customClassName={classNames("ciro-v1-w-full", "ciro-v1-mb-8")}
            disabled={
              !selectedDate ||
              !selectedWorkflowId ||
              contactsToEnrichLoading ||
              createPhoneNumbersRequestTransactionLoading ||
              selectedContactIds.size === 0 ||
              creditsAvailable < creditsNeeded
            }
          >
            Run
          </CiroButton>
          <div className={classNames("ciro-v1-w-1", "ciro-v1-h-1")}></div>
          {errorMsg && (
            <div
              className={classNames(
                "ciro-v1-text-red-900",
                "ciro-v1-bg-red-100",
                "ciro-v1-mt-4",
                "ciro-v1-p-2",
                "ciro-v1-rounded-lg",
                "ciro-v1-text-xs",
              )}
            >
              {errorMsg}
            </div>
          )}
        </div>
      </CiroModal>
    </>
  );
};

export default MobileNumbersRunModal;