import {
  useImperativeHandle,
  forwardRef,
} from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { gql, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import {
  CrmMobileConnection_UserAccountQuery,
  OrgContactObjectType,
  UpdateOrganizationMergeIntegrationInput,
} from "../../../__generated__/graphql";
import CiroDropDown from "../../shared/CiroDropdown";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import classNames from "classnames";

interface CrmConnectionSettingsFormHandle {
  onSubmit: () => void;
}

const CrmConnectionSettingsForm_UpdateOrganizationMergeIntegration = gql`
  mutation CrmConnectionSettingsForm_UpdateOrganizationMergeIntegration(
    $input: UpdateOrganizationMergeIntegrationInput!
  ) {
    updateOrganizationMergeIntegration(input: $input) {
      id
    }
  }
`;

interface CrmConnectionSettingsFormProps {
  initialData: CrmMobileConnection_UserAccountQuery["userAccount"];
  refetch: () => void;
  setCrmObjectType: (crmObjectType: OrgContactObjectType) => void;
  isInitialSetup: boolean;
}

const CrmConnectionSettingsForm = forwardRef<
  CrmConnectionSettingsFormHandle,
  CrmConnectionSettingsFormProps
>(
  (
    {
      initialData,
      refetch,
      setCrmObjectType,
      isInitialSetup,
    }: CrmConnectionSettingsFormProps,
    ref: React.ForwardedRef<CrmConnectionSettingsFormHandle>,
  ) => {

    const CrmIntegrationUpdateFormSchema = yup.object({
      contact_object_type: yup.string().when("$integrationName", {
        is: "HubSpot",
        then: (schema) =>
          schema
            .required("Required")
            .test(
              "not-contact",
              "Contact object type is not supported",
              (value) => value !== OrgContactObjectType.Contact,
            ),
        otherwise: (schema) =>
          schema.oneOf(
            [OrgContactObjectType.Contact, OrgContactObjectType.Lead],
            "Must be either CONTACT or LEAD",
          ),
      }),
    });

    const {
      control,
      formState: { errors },
      setValue,
      handleSubmit,
      trigger,
    } = useForm({
      resolver: yupResolver(CrmIntegrationUpdateFormSchema),
      defaultValues: {
        contact_object_type:
          initialData?.org?.organizationMergeIntegration
            ?.org_contact_object_type ||
          (initialData?.org?.organizationMergeIntegration?.integration ===
          "HubSpot"
            ? OrgContactObjectType.Contact
            : OrgContactObjectType.Lead),
      },
      context: {
        integrationName:
          initialData?.org?.organizationMergeIntegration?.integration || "",
      },
    });

    const [
      contactObjectType,
    ] = useWatch({
      control,
      name: [
        "contact_object_type",
      ],
    });

    const integrationName =
      initialData?.org?.organizationMergeIntegration?.integration || "";

    const checkValidity = async () => {
      const valid = await trigger();
      return valid;
    };

    const onSubmit = handleSubmit(async (data) => {
      try {
        const input: UpdateOrganizationMergeIntegrationInput = {
          org_contact_object_type:
            integrationName === "Salesforce"
              ? data.contact_object_type
              : OrgContactObjectType.Contact,
        };

        const result = await updateOrganizationMergeIntegration({
          variables: { input },
        });

        if (result.data) {
          refetch();
          toast.success("Settings saved successfully");
        }
      } catch (error) {
        console.error("Error updating CRM settings:", error);
        toast.error(
          "An error occurred while saving settings. Please try again.",
        );
      }
    });

    useImperativeHandle(ref, () => ({
      onSubmit,
      checkValidity,
    }));

    const [updateOrganizationMergeIntegration, { loading: updateLoading }] =
      useMutation(CrmConnectionSettingsForm_UpdateOrganizationMergeIntegration);

    return (
      <form>
        {integrationName === "Salesforce" && (
          <>
            <div>
              <CiroDropDown
                creatable={false}
                async={false}
                label="Which object needs to be enriched with new numbers"
                options={[
                  { label: "Lead", value: OrgContactObjectType.Lead },
                  { label: "Contact", value: OrgContactObjectType.Contact },
                ]}
                value={contactObjectType}
                onChange={(v) => {
                  setValue("contact_object_type", v as OrgContactObjectType, {
                    shouldValidate: true,
                  });
                  setCrmObjectType(v as OrgContactObjectType);
                }}
                error={errors.contact_object_type?.message as string}
              />
            </div>
          </>
        )}
        {!isInitialSetup && (
          <div
            className={classNames(
              "ciro-v1-pt-6",
              "ciro-v1-flex",
              "ciro-v1-justify-end",
            )}
          >
            <CiroButton
              analyticsField="save_crm_integration_changes"
              disabled={updateLoading}
              style={CiroButtonStyleEnum.LOUD}
              onClick={onSubmit}
            >
              Save changes
            </CiroButton>
          </div>
        )}
      </form>
    );
  },
);

export default CrmConnectionSettingsForm;
