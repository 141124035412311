import { gql, useMutation } from "@apollo/client";
import classNames from "classnames";
import {
  AdminViewOrganizationDetails_OrganizationFragment,
  AdminViewOrganizationDetails_UpdateOrganizationMutation,
  AdminViewOrganizationDetails_UpdateOrganizationMutationVariables,
  CreditPlanEnum,
  Maybe,
  OrganizationSubscriptionTierEnum,
} from "../../../__generated__/graphql";
import CiroTextInput from "../../shared/CiroTextInput";
import NumberFormat from "react-number-format";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import { MagnifyingGlassPlusIcon } from "@heroicons/react/20/solid";
import { OrganizationDetailsModalStateEnum } from "./AdminOrganizationDetails";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CiroDropDown from "../../shared/CiroDropdown";
import { useEffect } from "react";
import EnrichmentsDownloadButton from "../../shared/EnrichmentsDownloadButton/EnrichmentsDownloadButton";
import AdminAddApolloKey from "./AdminAddApolloKey";

const AdminViewOrganizationDetailsSchema = yup.object({
  // name: yup.string().required('Must have organization name'),
  creditsPlan: yup.object({
    incrementalCredits: yup
      .number()
      .when(["subscriptionTier", "plan"], ([subscriptionTier, plan], field) => {
        return subscriptionTier === OrganizationSubscriptionTierEnum &&
          plan !== CreditPlanEnum.NonRecurring
          ? field.positive()
          : field;
      }),
    premiumDataUnlockMultiplier: yup.number().min(1),
    plan: yup.string().oneOf(Object.values(CreditPlanEnum)),
  }),
  crmIntegrationEnabled: yup.boolean(),
  subscriptionTier: yup
    .string()
    .oneOf(Object.values(OrganizationSubscriptionTierEnum)),
});

export const AdminViewOrganizationDetails_Organization = gql`
  fragment AdminViewOrganizationDetails_Organization on Organization {
    crm_integration_enabled
    id
    ciro_id
    name
    premiumDataUnlockMultiplier
    subscription_tier
    credit_plan {
      incremental_credits
      plan
      balance
    }
    userAccounts {
      id
    }
    customPermissions {
      id
    }
    numCompletedEnrichments
  }
`;

const AdminViewOrganizationDetails_UpdateOrganization = gql`
  mutation AdminViewOrganizationDetails_UpdateOrganization(
    $creditPlan: CreditPlanInput
    $crmIntegrationEnabled: Boolean
    $organizationCiroId: Int!
    $premiumDataUnlockMultiplier: Int!
    $subscriptionTier: OrganizationSubscriptionTierEnum
  ) {
    updateOrganization(
      crmIntegrationEnabled: $crmIntegrationEnabled
      creditPlan: $creditPlan
      organizationCiroId: $organizationCiroId
      premiumDataUnlockMultiplier: $premiumDataUnlockMultiplier
      subscriptionTier: $subscriptionTier
    ) {
      success
    }
  }
`;

interface IAdminViewOrganizationDetailsProps {
  organization?: Maybe<AdminViewOrganizationDetails_OrganizationFragment>;
  setModalState: (v: OrganizationDetailsModalStateEnum) => void;
}

export const CREDIT_PLAN_TO_CYCLE_FORMATTING = {
  [CreditPlanEnum.Annual]: "year",
  [CreditPlanEnum.Monthly]: "month",
  [CreditPlanEnum.Quarterly]: "quarter",
};

const AdminViewOrganizationDetails = ({
  organization,
  setModalState,
}: IAdminViewOrganizationDetailsProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset: resetForm,
  } = useForm({
    resolver: yupResolver(AdminViewOrganizationDetailsSchema),
    defaultValues: {
      // name: organization?.name,
      crmIntegrationEnabled: organization?.crm_integration_enabled,
      subscriptionTier: organization?.subscription_tier,
      creditsPlan: {
        incrementalCredits: 500,
        plan: CreditPlanEnum.Monthly,
      },
      premiumDataUnlockMultiplier: organization?.premiumDataUnlockMultiplier,
    },
  });

  const [updateOrganization, { loading: updateOrgLoading }] = useMutation<
    AdminViewOrganizationDetails_UpdateOrganizationMutation,
    AdminViewOrganizationDetails_UpdateOrganizationMutationVariables
  >(AdminViewOrganizationDetails_UpdateOrganization);

  const onSubmit = async (data: any) => {
    const variables = {
      organizationCiroId: organization?.ciro_id,
      subscriptionTier: data.subscriptionTier,
      premiumDataUnlockMultiplier: Number(data.premiumDataUnlockMultiplier),
      crmIntegrationEnabled: data.crmIntegrationEnabled,
    } as AdminViewOrganizationDetails_UpdateOrganizationMutationVariables;

    if (
      data.subscriptionTier === OrganizationSubscriptionTierEnum.Professional
    ) {
      variables.creditPlan = {
        incrementalCredits: data.creditsPlan.incrementalCredits,
        plan: data.creditsPlan.plan,
      };
    }

    updateOrganization({
      variables,
    });
  };

  const [creditsCycle, crmIntegrationEnabled, subscriptionTier] = useWatch({
    control,
    name: ["creditsPlan.plan", "crmIntegrationEnabled", "subscriptionTier"],
  });

  useEffect(() => {
    if (organization) {
      resetForm({
        // name: organization.name,
        creditsPlan: {
          incrementalCredits:
            organization.credit_plan?.incremental_credits || 500,
          plan: organization.credit_plan?.plan || CreditPlanEnum.Monthly,
        },
        crmIntegrationEnabled: organization.crm_integration_enabled,
        subscriptionTier: organization.subscription_tier,
        premiumDataUnlockMultiplier: organization.premiumDataUnlockMultiplier,
      });
    }
  }, [organization, resetForm]);

  if (!organization) {
    return null;
  }

  return (
    <div
      className={classNames("ciro-v1-overflow-auto", "ciro-v1-max-h-[80vh]")}
    >
      <div className={classNames("ciro-v1-flex", "ciro-v1-justify-between")}>
        <div className={classNames("ciro-v1-font-medium", "ciro-v1-text-lg")}>
          {organization.name}
        </div>
      </div>
      <div
        className={classNames(
          "ciro-v1-pt-4",
          "ciro-v1-grid",
          "ciro-v1-grid-cols-1",
          "ciro-v1-gap-4",
          "md:ciro-v1-grid-cols-2",
        )}
      >
        <CiroTextInput
          label="Organizaton Name"
          placeholder="Name"
          value={organization?.name || undefined}
          disabled
        />
        <CiroDropDown
          placeholder="Subscription Tier"
          label="Subscription Tier"
          value={subscriptionTier}
          options={Object.values(OrganizationSubscriptionTierEnum).map(
            (subTier) => {
              return {
                label: subTier,
                value: subTier,
              };
            },
          )}
          error={errors.subscriptionTier?.message}
          onChange={(v) => {
            setValue("subscriptionTier", v);
          }}
        />
        <CiroDropDown
          placeholder="Allow organization to link CRM"
          label="CRM Integration Enabled"
          value={crmIntegrationEnabled}
          options={[
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
          ]}
          error={errors.crmIntegrationEnabled?.message}
          onChange={(v) => {
            setValue("crmIntegrationEnabled", v);
          }}
        />
        <CiroTextInput
          label="Premium Data Credits Multiplier"
          placeholder="Choose a whole number"
          type="number"
          defaultValue={50}
          register={register("premiumDataUnlockMultiplier")}
        />
        <div
          className={classNames([
            "ciro-v1-font-medium",
            "ciro-v1-pt-4",
            "ciro-v1-flex",
            "ciro-v1-items-center",
          ])}
        >
          Users
          <CiroButton
            analyticsField="View Custom Filters"
            style={CiroButtonStyleEnum.UNSTYLED}
            onClick={() => {
              setModalState(OrganizationDetailsModalStateEnum.USERS);
            }}
          >
            <MagnifyingGlassPlusIcon
              className={classNames(["ciro-v1-ml-2", "ciro-v1-h-5 w-5"])}
              aria-hidden="true"
            />
          </CiroButton>
        </div>
        <div className={classNames(["ciro-v1-pt-4", "ciro-v1-font-medium"])}>
          {organization?.userAccounts.length}
        </div>
        <div
          className={classNames([
            "ciro-v1-font-medium",
            "ciro-v1-pt-4",
            "ciro-v1-flex",
            "ciro-v1-items-center",
          ])}
        >
          Custom Permissions
          <CiroButton
            analyticsField="View Custom Filters"
            style={CiroButtonStyleEnum.UNSTYLED}
            onClick={() => {
              setModalState(
                OrganizationDetailsModalStateEnum.CUSTOM_PERMISSIONS,
              );
            }}
          >
            <MagnifyingGlassPlusIcon
              className={classNames(["ciro-v1-ml-2", "ciro-v1-h-5 w-5"])}
              aria-hidden="true"
            />
          </CiroButton>
        </div>
        <div className={classNames(["ciro-v1-pt-4", "ciro-v1-font-medium"])}>
          {organization?.customPermissions?.length || 0}
        </div>
        {subscriptionTier !== OrganizationSubscriptionTierEnum.Free && (
          <>
            <div
              className={classNames([
                "ciro-v1-font-medium",
                "ciro-v1-pt-4",
                "ciro-v1-flex",
                "ciro-v1-items-center",
              ])}
            >
              Credits Plan
              <CiroButton
                analyticsField="View credits plan details"
                style={CiroButtonStyleEnum.UNSTYLED}
                onClick={() => {
                  setModalState(
                    OrganizationDetailsModalStateEnum.CREDIT_PLAN_DETAILS,
                  );
                }}
              >
                <MagnifyingGlassPlusIcon
                  className={classNames(["ciro-v1-ml-2", "ciro-v1-h-5 w-5"])}
                  aria-hidden="true"
                />
              </CiroButton>
            </div>
            <div
              className={classNames("ciro-v1-font-medium", "md:ciro-v1-pt-4", {
                "ciro-v1-text-rose-500":
                  (organization?.credit_plan?.balance || 0) <= 0,
              })}
            >
              {Boolean(organization?.credit_plan?.balance) && (
                <span>
                  <NumberFormat
                    value={organization?.credit_plan?.balance}
                    thousandSeparator={true}
                    displayType="text"
                  />
                  &nbsp;credits remaining
                </span>
              )}
            </div>
            <CiroDropDown
              placeholder="Select New Credit Frequency"
              label="New Credit Cycle"
              value={creditsCycle}
              options={Object.values(CreditPlanEnum).map((creditPlan) => {
                return {
                  label: creditPlan,
                  value: creditPlan,
                };
              })}
              error={errors.subscriptionTier?.message}
              onChange={(v) => {
                setValue("creditsPlan.plan", v);
              }}
            />
            {creditsCycle !== CreditPlanEnum.NonRecurring && (
              <CiroTextInput
                label={`Number of credits per ${CREDIT_PLAN_TO_CYCLE_FORMATTING[creditsCycle]}`}
                register={register("creditsPlan.incrementalCredits")}
                error={errors.creditsPlan?.incrementalCredits?.message}
              />
            )}
          </>
        )}
      </div>
      <AdminAddApolloKey orgId={organization.ciro_id} />
      {Boolean(organization?.numCompletedEnrichments) && (
        <div>
          <div
            className={classNames([
              "ciro-v1-font-medium",
              "ciro-v1-pt-4",
              "ciro-v1-flex",
              "ciro-v1-items-center",
              "ciro-v1-pb-2",
            ])}
          >
            Enrichments
          </div>
          <EnrichmentsDownloadButton
            organizationCiroId={organization.ciro_id}
          ></EnrichmentsDownloadButton>
        </div>
      )}
      <div
        className={classNames(
          "ciro-v1-pt-4",
          "ciro-v1-flex",
          "ciro-v1-justify-end",
        )}
      >
        <CiroButton
          analyticsField="Edit Organization"
          onClick={handleSubmit(onSubmit)}
          disabled={updateOrgLoading}
        >
          Submit
        </CiroButton>
      </div>
    </div>
  );
};

export default AdminViewOrganizationDetails;
