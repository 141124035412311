import classNames from "classnames";
import ApolloIntegrationApiKeyForm, {
  ApolloIntegrationApiKeyForm_ApolloIntegration,
} from "../../components/mobileNumbers/mobileNumbersIntegrationModal/apollo/ApolloIntegrationApiKeyForm";
import CiroContainer from "../../components/shared/CiroContainer";
import { gql, useMutation, useQuery } from "@apollo/client";
import ApolloIntegrationUpdateForm, {
  ApolloIntegrationUpdateForm_ApolloIntegration,
} from "../../components/mobileNumbers/mobileNumbersIntegrationModal/apollo/ApolloIntegrationUpdateForm";
import { useEffect, useState } from "react";
import CiroButton from "../../components/shared/CiroButton";
import LinkSlashIcon from "../../assets/img/icons/LinkSlashIcon";
import ApolloIcon from "../../assets/img/icons/ApolloIcon";
import CiroBreadCrumbs from "../../components/shared/CiroBreadCrumbs";
import { useNavigate } from "react-router-dom";
import { useFragment } from "../../__generated__";
import {
  ApolloIntegrationApiKeyForm_ApolloIntegrationFragment,
  ApolloIntegrationUpdateForm_ApolloIntegrationFragment,
  ApolloMobileConnection_ApolloIntegrationQuery,
  ApolloMobileConnection_ApolloIntegrationQueryVariables,
} from "../../__generated__/graphql";
import SkeletonLoading from "../../components/shared/SkeletonLoading";

export const ApolloMobileConnection_ApolloIntegration = gql`
  query ApolloMobileConnection_ApolloIntegration {
    userAccount {
      org {
        apolloIntegration {
          api_key
          bad_number_dispositions
          ...ApolloIntegrationUpdateForm_ApolloIntegration
          ...ApolloIntegrationApiKeyForm_ApolloIntegration
        }
      }
    }
  }
  ${ApolloIntegrationUpdateForm_ApolloIntegration}
  ${ApolloIntegrationApiKeyForm_ApolloIntegration}
`;

const ApolloIntegrationApiKeyForm_DeleteApolloIntegration = gql`
  mutation ApolloIntegrationApiKeyForm_DeleteApolloIntegration {
    deleteApolloIntegration
  }
`;

const ApolloMobileConnection = () => {
  const [apolloIntegrationStep, setApolloIntegrationStep] = useState<
    "api-key" | "update"
  >("api-key");
  const navigator = useNavigate();
  const [isInitialSetup, setIsInitialSetup] = useState(true);
  const { data, loading } = useQuery<
    ApolloMobileConnection_ApolloIntegrationQuery,
    ApolloMobileConnection_ApolloIntegrationQueryVariables
  >(ApolloMobileConnection_ApolloIntegration);

  const apolloIntegrationApiKeyFormFragment = useFragment(
    ApolloIntegrationApiKeyForm_ApolloIntegration,
    data?.userAccount?.org?.apolloIntegration,
  ) as ApolloIntegrationApiKeyForm_ApolloIntegrationFragment;

  const apolloIntegrationUpdateFormFragment = useFragment(
    ApolloIntegrationUpdateForm_ApolloIntegration,
    data?.userAccount?.org?.apolloIntegration,
  ) as ApolloIntegrationUpdateForm_ApolloIntegrationFragment;

  const [
    deleteApolloIntegration,
    { loading: deleteLoading, error: deleteError },
  ] = useMutation(ApolloIntegrationApiKeyForm_DeleteApolloIntegration);

  useEffect(() => {
    if (data?.userAccount?.org?.apolloIntegration?.api_key) {
      setApolloIntegrationStep("update");
    }
    if (data?.userAccount?.org?.apolloIntegration?.bad_number_dispositions) {
      setIsInitialSetup(false);
    }
  }, [data, isInitialSetup]);

  if (loading)
    return (
      <div
        className={classNames(
          "ciro-v1-w-full",
          "ciro-v1-px-4",
          "ciro-v1-pt-16",
        )}
      >
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-flex-row",
            "ciro-v1-justify-between",
            "ciro-v1-gap-4",
            "ciro-v1-w-full",
          )}
        >
          <div className={classNames("ciro-v1-w-full")}>
            <SkeletonLoading numSkeletons={1} skeletonHeight={"6rem"} />
          </div>
          <div className={classNames("ciro-v1-w-full")}>
            <SkeletonLoading numSkeletons={1} skeletonHeight={"20rem"} />
          </div>
        </div>
      </div>
    );

  return (
    <CiroContainer
      className={classNames("ciro-v1-bg-zinc-100", "ciro-v1-w-full")}
    >
      <CiroBreadCrumbs
        values={["Enrich CRM", "Apollo Connection"]}
        href="/enrich-crm"
      />
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-justify-between",
          "ciro-v1-w-full",
        )}
      >
        <h1
          className={classNames(
            "ciro-v1-text-neutral-900",
            "ciro-v1-text-xl",
            "ciro-v1-font-semibold",
          )}
        >
          {isInitialSetup ? "Connect with Apollo" : "Manage Apollo connection"}
        </h1>
        {data?.userAccount?.org?.apolloIntegration?.api_key && (
          <>
            <CiroButton
              analyticsField="Delete ApolloIntegration"
              customClassName="ciro-v1-mb-5"
              disabled={loading || deleteLoading}
              onClick={async () => {
                await deleteApolloIntegration();
                navigator("/enrich-crm");
              }}
            >
              <LinkSlashIcon />
              <span className={classNames("ciro-v1-ml-2")}>
                Remove Apollo Connection
              </span>
            </CiroButton>
            {deleteError && (
              <div className={classNames("ciro-v1-text-red-500")}>
                {deleteError.message}
              </div>
            )}
          </>
        )}
      </div>
      {apolloIntegrationStep === "api-key" && (
        <div className={classNames("ciro-v1-flex", "ciro-v1-gap-6")}>
          <div className={classNames("ciro-v1-mt-4")}>
            <ApolloIcon height="30" width="30" />
            <h1
              className={classNames(
                "ciro-v1-text-neutral-900",
                "ciro-v1-text-base",
                "ciro-v1-font-bold",
                "ciro-v1-mb-2",
                "ciro-v1-mt-4",
              )}
            >
              Continue With Apollo
            </h1>
            <div
              className={classNames(
                "ciro-v1-text-neutral-500",
                "ciro-v1-text-sm",
                "ciro-v1-font-normal",
              )}
            >
              Enter your Apollo API key to the right to connect you Apollo
              account
            </div>
          </div>
          <div
            className={classNames(
              "ciro-v1-bg-white",
              "ciro-v1-rounded-lg",
              "ciro-v1-border",
              "ciro-v1-p-4",
              "ciro-v1-border-neutral-200",
              "ciro-v1-w-full",
            )}
          >
            <ApolloIntegrationApiKeyForm
              apolloIntegration={apolloIntegrationApiKeyFormFragment}
              setApolloIntegrationStep={setApolloIntegrationStep}
            />
          </div>
        </div>
      )}
      {apolloIntegrationStep === "update" && (
        <div className={classNames("ciro-v1-flex", "ciro-v1-gap-6")}>
          <div>
            <h1
              className={classNames(
                "ciro-v1-text-neutral-900",
                "ciro-v1-text-base",
                "ciro-v1-font-bold",
                "ciro-v1-mb-2",
              )}
            >
              Connection Settings
            </h1>
            <div
              className={classNames(
                "ciro-v1-text-neutral-500",
                "ciro-v1-text-sm",
                "ciro-v1-font-normal",
              )}
            >
              Define the criteria for determining call success and number
              validity in your Apollo integration.
            </div>
          </div>
          <div
            className={classNames(
              "ciro-v1-bg-white",
              "ciro-v1-p-4",
              "ciro-v1-rounded-lg",
              "ciro-v1-border",
              "ciro-v1-border-neutral-200",
              "ciro-v1-w-full",
            )}
          >
            <ApolloIntegrationUpdateForm
              apolloIntegration={apolloIntegrationUpdateFormFragment}
              initialSetup={isInitialSetup}
            />
          </div>
        </div>
      )}
    </CiroContainer>
  );
};

export default ApolloMobileConnection;
