import React from "react";
import classNames from "classnames";

interface ICiroTableProps {
  borderless?: boolean;
  children: any;
  containerClassName?: string;
}

const CiroTable = ({
  borderless = false,
  children,
  containerClassName = "",
}: ICiroTableProps) => {
  return (
    <div
      className={classNames([
        "ciro-v1-bg-white",
        {
          "ciro-v1-border-1": !borderless,
          "ciro-v1-border-gray-200": !borderless,
          "ciro-v1-border-separate": !borderless,
        },
        "ciro-v1-rounded-lg",
        "ciro-v1-text-left",
        "ciro-v1-relative",
        "ciro-v1-border-b-0",
        containerClassName,
      ])}
    >
      <table
        // Unsure why w-full is not working, need to use this instead
        style={{
          width: "100%",
        }}
        className={classNames([
          "ciro-v1-table",
          "ciro-v1-table-fixed",
          "ciro-w-full",
          "ciro-v1-rounded-lg",
        ])}
      >
        {children}
      </table>
    </div>
  );
};

export default CiroTable;
