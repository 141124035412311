import { gql, useQuery } from "@apollo/client";
import {
  MobileNumbersWorkflowsTable_WorkflowsQuery,
  MobileNumbersWorkflowsTable_WorkflowsQueryVariables,
} from "../../../__generated__/graphql";
import SkeletonLoading from "../../shared/SkeletonLoading";
import CiroTableHeader, {
  CiroTableHeaderAlignEnum,
} from "../../shared/CiroTable/CiroTableHeader";
import CiroTable from "../../shared/CiroTable/CiroTable";
import classNames from "classnames";
import CiroTableRow from "../../shared/CiroTable/CiroTableRow";
import CiroTableCell, {
  CiroTableCellAlignEnum,
} from "../../shared/CiroTable/CiroTableCell";
import { dateToTimeAgo } from "../../../utils/formatters";
import { PencilIcon, PlayCircleIcon } from "@heroicons/react/24/outline";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import { useNavigate } from "react-router-dom";
import MobileNumbersRunModal from "../MobileNumbersRunModal";
import { useState } from "react";

export const MobileNumbersWorkflowsTable_Workflows = gql`
  query MobileNumbersWorkflowsTable_Workflows {
    organization {
      phoneNumberRequestWorkflows {
        id
        name
        segmentation
        runCount
        lastRunAt
        createdAt
        updatedAt
      }
    }
  }
`;

const MobileNumbersWorkflowsTable = () => {
  const { data: workflowsData, loading: workflowsLoading } = useQuery<
    MobileNumbersWorkflowsTable_WorkflowsQuery,
    MobileNumbersWorkflowsTable_WorkflowsQueryVariables
  >(MobileNumbersWorkflowsTable_Workflows);
  const navigate = useNavigate();

  const [selectedWorkflowId, setSelectedWorkflowId] = useState<number | null>(
    null,
  );

  if (workflowsLoading) {
    return <SkeletonLoading numSkeletons={25} skeletonHeight={"3rem"} />;
  }

  const headers = [
    <CiroTableHeader key="name" isFirst={true}>
      Name
    </CiroTableHeader>,
    <CiroTableHeader key="runs">Runs</CiroTableHeader>,
    <CiroTableHeader key="last-run-at">Last run at</CiroTableHeader>,
    <CiroTableHeader align={CiroTableHeaderAlignEnum.RIGHT} key="manage">
      Manage
    </CiroTableHeader>,
  ];

  return (
    <div>
      <CiroTable>
        <thead className={classNames("ciro-v1-table-header-group")}>
          <CiroTableRow clickable={false}>{headers}</CiroTableRow>
        </thead>
        <tbody className={classNames("ciro-v1-table-row-group")}>
          {workflowsData?.organization?.phoneNumberRequestWorkflows.map(
            (workflow) => {
              return (
                <CiroTableRow key={workflow.id} clickable={false}>
                  <CiroTableCell>{workflow.name}</CiroTableCell>
                  <CiroTableCell>{workflow.runCount}</CiroTableCell>
                  <CiroTableCell>
                    {workflow.lastRunAt
                      ? dateToTimeAgo(workflow.lastRunAt)
                      : "-"}
                  </CiroTableCell>
                  <CiroTableCell lastRight align={CiroTableCellAlignEnum.RIGHT}>
                    <div
                      className={classNames(
                        "ciro-v1-flex",
                        "ciro-v1-gap-2",
                        "ciro-v1-justify-end",
                      )}
                    >
                      <CiroButton
                        analyticsField="edit-workflow"
                        analyticsProps={{ workflowId: workflow.id }}
                        style={CiroButtonStyleEnum.UNSTYLED}
                        onClick={() => {
                          navigate(`/enrich-crm/workflow/${workflow.id}`);
                        }}
                      >
                        <PencilIcon className={classNames("ciro-v1-h-4")} />
                      </CiroButton>
                      <CiroButton
                        analyticsField="run-workflow"
                        analyticsProps={{ workflowId: workflow.id }}
                        style={CiroButtonStyleEnum.UNSTYLED}
                        onClick={() => {
                          setSelectedWorkflowId(Number(workflow.id));
                        }}
                      >
                        <PlayCircleIcon className={classNames("ciro-v1-h-4")} />
                      </CiroButton>
                    </div>
                  </CiroTableCell>
                </CiroTableRow>
              );
            },
          )}
        </tbody>
        <MobileNumbersRunModal
          isOpen={selectedWorkflowId !== null}
          onClose={() => setSelectedWorkflowId(null)}
          startingWorkflowId={selectedWorkflowId}
        />
      </CiroTable>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-w-full",
          "ciro-v1-justify-center",
          "ciro-v1-mt-4",
        )}
      >
        <CiroButton
          analyticsField="add-workflow"
          style={CiroButtonStyleEnum.LOUD}
          onClick={() => {
            navigate(`/enrich-crm/workflow`);
          }}
        >
          + Add workflow
        </CiroButton>
      </div>
    </div>
  );
};

export default MobileNumbersWorkflowsTable;
