import classNames from "classnames";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroTitle from "../../components/shared/CiroTitle";
import MobileNumbersOverview from "../../components/mobileNumbers/MobileNumbersOverview";
import { gql, useQuery } from "@apollo/client";
import CiroButton, {
  CiroButtonStyleEnum,
} from "../../components/shared/CiroButton";
import { useState } from "react";
import MobileNumbersRefreshButton from "../../components/mobileNumbers/MobileNumbersRefreshButton";
import DotIcon from "../../assets/img/icons/DotIcon";
import SalesforceIcon from "../../assets/img/icons/SalesforceIcon";
import ApolloIcon from "../../assets/img/icons/ApolloIcon";
import { useNavigate } from "react-router-dom";
import {
  MobileNumbers_TransactionCountQuery,
  MobileNumbers_TransactionCountQueryVariables,
  MobileNumbers_UserAccountQuery,
  MobileNumbers_UserAccountQueryVariables,
} from "../../__generated__/graphql";
import HubspotIcon from "../../assets/img/icons/HubspotIcon";
import MobileNumbersBodyContainer from "../../components/mobileNumbers/MobileNumbersBodyContainer";
import MobileNumbersIntegrationModal from "../../components/mobileNumbers/mobileNumbersIntegrationModal";
import { ForwardIcon } from "@heroicons/react/20/solid";
import MobileNumbersRunModal from "../../components/mobileNumbers/MobileNumbersRunModal";

export const MobileNumbers_UserAccount = gql`
  query MobileNumbers_UserAccount {
    userAccount {
      org {
        apolloIntegration {
          id
        }
        organizationMergeIntegration {
          integration
          invalid_number_statuses
        }
      }
    }
  }
`;

export const MobileNumbers_TransactionCount = gql`
  query MobileNumbers_TransactionCount {
    phoneNumbersRequestTransactionsCount
  }
`;

interface IMobileNumbersProps {
  startOnWorkflows?: boolean;
}

const MobileNumbers = ({ startOnWorkflows }: IMobileNumbersProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: userAccountData, loading: userAccountLoading } = useQuery<
    MobileNumbers_UserAccountQuery,
    MobileNumbers_UserAccountQueryVariables
    >(MobileNumbers_UserAccount);
  const { data: transactionCountData } = useQuery<
    MobileNumbers_TransactionCountQuery,
    MobileNumbers_TransactionCountQueryVariables
  >(MobileNumbers_TransactionCount);
  const [integrationModalOpen, setIntegrationModalOpen] = useState(false);
  const navigator = useNavigate();

  const hasApolloIntegration =
    userAccountData?.userAccount?.org?.apolloIntegration != null;
  //Since it is possible to have an organizationMergeIntegration without having
  //set up ciro mobiles settings, we need to check invalid_number_statuses instead of
  //just that a merge integration exists.
  const hasCrmIntegration =
    userAccountData?.userAccount?.org?.organizationMergeIntegration
      ?.invalid_number_statuses != null;
  const hasIntegration = hasApolloIntegration || hasCrmIntegration;

  return (
    <CiroContainer className={classNames("ciro-v1-bg-zinc-100")}>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-flex-row",
          "ciro-v1-justify-between",
          "ciro-v1-w-full",
          "ciro-v1-items-center",
        )}
      >
      </div>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-justify-between",
          "ciro-v1-w-full",
        )}
      >
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-flex-row",
            "ciro-v1-items-center",
          )}
        >
          <CiroTitle title="Enrich CRM" />
          <span className={classNames("ciro-v1-px-2")}>
            <MobileNumbersRefreshButton />
          </span>
        </div>
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-flex-row",
            "ciro-v1-items-center",
            "ciro-v1-gap-2",
          )}
        >
          {!hasIntegration && (
            <CiroButton
              analyticsField="Open Integration Settings"
              onClick={() => setIntegrationModalOpen(true)}
            >
              <div
                className={classNames(
                  "ciro-v1-flex",
                  "ciro-v1-flex-row",
                  "ciro-v1-items-center",
                )}
              >
                Connect your CRM
              </div>
            </CiroButton>
          )}
          {hasApolloIntegration && (
            <CiroButton
              analyticsField="Open Apollo settings"
              onClick={() => navigator("/enrich-crm/apollo-integration")}
            >
              <div
                className={classNames(
                  "ciro-v1-flex",
                  "ciro-v1-flex-row",
                  "ciro-v1-items-center",
                )}
              >
                <span className={classNames("ciro-v1-mr-2")}>
                  <ApolloIcon />
                </span>
                Manage Apollo connection
                <span
                  className={classNames(
                    "ciro-v1-text-green-500",
                    "ciro-v1-ml-2",
                  )}
                >
                  <DotIcon />
                </span>
              </div>
            </CiroButton>
          )}
          {hasCrmIntegration && (
            <CiroButton
              analyticsField="Open crm mobile connection settings"
              onClick={() => {
                navigator("/enrich-crm/crm-integration");
              }}
            >
              <div
                className={classNames(
                  "ciro-v1-flex",
                  "ciro-v1-flex-row",
                  "ciro-v1-items-center",
                )}
              >
                <span className={classNames("ciro-v1-mr-2")}>
                  {userAccountData?.userAccount?.org
                    ?.organizationMergeIntegration?.integration ===
                  "Salesforce" ? (
                    <SalesforceIcon />
                  ) : (
                    <HubspotIcon />
                  )}
                </span>
                Manage{" "}
                {
                  userAccountData?.userAccount?.org
                    ?.organizationMergeIntegration?.integration
                }{" "}
                connection
                <span
                  className={classNames(
                    "ciro-v1-text-green-500",
                    "ciro-v1-ml-2",
                  )}
                >
                  <DotIcon />
                </span>
              </div>
            </CiroButton>
          )}
          {hasIntegration && (
            <>
              <CiroButton
                analyticsField="Open Run Mobile Numbers modal"
                style={CiroButtonStyleEnum.LOUD}
                onClick={() => setIsModalOpen(true)}
              >
                <ForwardIcon
                  className={classNames(
                    "ciro-v1-w-4",
                    "ciro-v1-h-4",
                    "ciro-v1-mr-2",
                  )}
                />
                Run now
              </CiroButton>
              <MobileNumbersRunModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
              />
            </>
          )}
        </div>
      </div>
      <MobileNumbersOverview />
      {!userAccountLoading && (
        <MobileNumbersBodyContainer
          startOnWorkflows={Boolean(startOnWorkflows)}
          hasIntegration={hasIntegration}
          hasTransactions={Boolean(transactionCountData?.phoneNumbersRequestTransactionsCount)}
        />
      )}
      <MobileNumbersIntegrationModal
        isOpen={integrationModalOpen}
        onClose={() => setIntegrationModalOpen(false)}
      />
    </CiroContainer>
  );
};

export default MobileNumbers;
