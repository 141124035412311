import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import {
  CrmConnectionOverrideForm_GetCrmObjectFieldsQuery,
  CrmConnectionOverrideForm_GetCrmObjectFieldsQueryVariables,
  CrmConnectionOverrideForm_UpdateOrganizationMergeIntegrationMutation,
  CrmConnectionOverrideForm_UpdateOrganizationMergeIntegrationMutationVariables,
  CrmMobileConnection_UserAccountQuery,
  OrgContactObjectType,
  UpdateOrganizationMergeIntegrationInput,
} from "../../../__generated__/graphql";
import CiroDropDown from "../../shared/CiroDropdown";
import CiroTextInput from "../../shared/CiroTextInput";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";

export interface DropdownOption {
  label: string;
  value: string;
}

interface CrmConnectionOverrideFormHandle {
  onSubmit: () => void;
}

const CrmConnectionOverrideForm_UpdateOrganizationMergeIntegration = gql`
  mutation CrmConnectionOverrideForm_UpdateOrganizationMergeIntegration(
    $input: UpdateOrganizationMergeIntegrationInput!
  ) {
    updateOrganizationMergeIntegration(input: $input) {
      id
    }
  }
`;

const CrmConnectionOverrideForm_GetCrmObjectFields = gql`
  query CrmConnectionOverrideForm_GetCrmObjectFields($objectType: String!) {
    organizationMergeIntegrationCrmObjectFields(objectType: $objectType) {
      success
      fields {
        crmRecordType
        fieldName
        fieldLabel
      }
    }
  }
`;

interface CrmConnectionOverrideFormProps {
  initialData: CrmMobileConnection_UserAccountQuery["userAccount"];
  refetch: () => void;
  crmObjectType: OrgContactObjectType | null;
  isInitialSetup: boolean;
}

const CrmConnectionOverrideForm = forwardRef(
  (
    {
      initialData,
      refetch,
      crmObjectType,
      isInitialSetup,
    }: CrmConnectionOverrideFormProps,
    ref: React.Ref<CrmConnectionOverrideFormHandle>,
  ) => {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const timestamp = initialData?.org?.organizationMergeIntegration?.timestamp_field ? true : false;
    // Remove this when we update the timestamp field
    // linear.app/ciro/issue/CIR-2571/update-timestamp-field-so-it-is-a-field-picker)
    // const [timestamp, setTimestamp] = useState(
    //   initialData?.org?.organizationMergeIntegration?.timestamp_field
    //     ? true
    //     : false,
    // );

    const [crmObjectFields, setCrmObjectFields] = useState<DropdownOption[]>(
      [],
    );

    const CrmIntegrationOverrideFormSchema = yup.object({
      overwrite: yup
        .string()
        .oneOf(["YES", "SAME_FIELD_ONLY", "NO"], "Invalid overwrite option")
        .required("Overwrite option is required"),
      timestampField: yup.string().when([], {
        is: () => timestamp,
        then: (schema) => schema.required("Timestamp field is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      defaultPhoneWriteField: yup.string().when("overwrite", {
        is: (val: string) => val === "YES" || val === "SAME_FIELD_ONLY",
        then: (schema) => schema.required("Timestamp field is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    });

    const {
      control,
      formState: { errors },
      trigger,
      getValues,
      setValue,
      handleSubmit,
    } = useForm({
      resolver: yupResolver(CrmIntegrationOverrideFormSchema),
      defaultValues: {
        overwrite:
          initialData?.org?.organizationMergeIntegration?.overwrite || "NO",
        timestampField:
          initialData?.org?.organizationMergeIntegration?.timestamp_field || "",
        defaultPhoneWriteField:
          initialData?.org?.organizationMergeIntegration
            ?.default_phone_write_field || "",
      },
    });

    const [overwrite, timestampField, defaultPhoneWriteField] = useWatch({
      control,
      name: ["overwrite", "timestampField", "defaultPhoneWriteField"],
    });

    const [updateOrganizationMergeIntegration, { loading: updateLoading }] =
      useMutation<
        CrmConnectionOverrideForm_UpdateOrganizationMergeIntegrationMutation,
        CrmConnectionOverrideForm_UpdateOrganizationMergeIntegrationMutationVariables
      >(CrmConnectionOverrideForm_UpdateOrganizationMergeIntegration);

    const [getCrmObjectFields, { loading: getCrmObjectFieldsLoading }] =
      useLazyQuery<
        CrmConnectionOverrideForm_GetCrmObjectFieldsQuery,
        CrmConnectionOverrideForm_GetCrmObjectFieldsQueryVariables
      >(CrmConnectionOverrideForm_GetCrmObjectFields, {
        variables: { objectType: crmObjectType! },
        onCompleted: (data) => {
          if (data.organizationMergeIntegrationCrmObjectFields?.fields) {
            setCrmObjectFields(
              data.organizationMergeIntegrationCrmObjectFields.fields.map(
                (f) => ({
                  label: f.fieldLabel,
                  value: f.fieldName,
                }),
              ),
            );
          }
        },
      });

    useEffect(() => {
      if (crmObjectType !== null) {
        getCrmObjectFields({ variables: { objectType: crmObjectType } });
      }
    }, [crmObjectType, getCrmObjectFields]);

    const checkValidity = async () => {
      const valid = await trigger();
      return valid;
    };

    const onSubmit = handleSubmit(async (data) => {
      try {
        const input: UpdateOrganizationMergeIntegrationInput = {
          overwrite: data.overwrite,
          timestamp_field: timestamp ? data.timestampField : null,
          default_phone_write_field:
            data.overwrite === "YES" ? data.defaultPhoneWriteField : null,
        };

        const result = await updateOrganizationMergeIntegration({
          variables: { input },
        });

        if (result.data) {
          refetch();
          toast.success("Override settings saved successfully");
        }
      } catch (error) {
        console.error("Error updating override settings:", error);
        toast.error(
          "An error occurred while saving override settings. Please try again.",
        );
      }
    });

    useImperativeHandle(ref, () => ({
      onSubmit,
      checkValidity,
    }));

    return (
      <form onSubmit={onSubmit}>
        <div className="ciro-v1-pt-4">
          <CiroDropDown
            label={"Write new numbers back to my CRM"}
            options={[
              { label: "Always write", value: "YES" },
              {
                label: "Only overwrite confirmed bad numbers",
                value: "SAME_FIELD_ONLY",
              },
              { label: "Don't write", value: "NO" },
            ]}
            value={overwrite}
            onChange={(v) => {
              setValue("overwrite", v as string);
              if (v !== "YES") {
                setValue("defaultPhoneWriteField", "");
              }
            }}
          />
        </div>
        {(getValues("overwrite") === "YES" ||
          getValues("overwrite") === "SAME_FIELD_ONLY") && (
          <>
            <div className="ciro-v1-pt-4">
              <CiroDropDown
                label="Phone number field to write to"
                // Assume objectFields are fetched and passed as props or managed within the component
                options={crmObjectFields}
                value={defaultPhoneWriteField}
                isLoading={getCrmObjectFieldsLoading}
                onChange={(v) => {
                  setValue("defaultPhoneWriteField", v as string);
                }}
                error={errors.defaultPhoneWriteField?.message as string}
              />
            </div>
            {/* Remove this when we update the timestamp field
            https://linear.app/ciro/issue/CIR-2571/update-timestamp-field-so-it-is-a-field-picker) */}
            {/* <div className="ciro-v1-pt-4">
              <CiroCheckbox
                label="Create timestamp field to indicate when the contact was updated by Ciro with a new number"
                checked={timestamp}
                onClick={() => {
                  setTimestamp(!timestamp);
                  if (!timestamp) {
                    setValue("timestampField", "");
                  }
                }}
              />
            </div> */}
            {timestamp && (
              <div className="ciro-v1-pt-4">
                <CiroTextInput
                  label="Enter a name for the timestamp field"
                  value={timestampField}
                  onChange={(v) => {
                    setValue("timestampField", v.target.value);
                  }}
                  error={errors.timestampField?.message as string}
                />
              </div>
            )}
          </>
        )}
        {!isInitialSetup && (
          <div className="ciro-v1-pt-6 ciro-v1-flex ciro-v1-justify-end">
            <CiroButton
              analyticsField="save_override_settings"
              disabled={updateLoading}
              style={CiroButtonStyleEnum.LOUD}
              onClick={onSubmit}
            >
              Save Changes
            </CiroButton>
          </div>
        )}
      </form>
    );
  },
);

export default CrmConnectionOverrideForm;
