import { gql, useMutation, useQuery } from "@apollo/client";
import classNames from "classnames";
import * as Yup from "yup";
import CiroTextInput from "../shared/CiroTextInput";
import CiroDropDown from "../shared/CiroDropdown";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo } from "react";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import {
  CrmDisposition,
  IntegrationType,
  MobileNumbersWorkflowForm_PhoneNumberRequestWorkflowQuery,
  MobileNumbersWorkflowForm_PhoneNumberRequestWorkflowQueryVariables,
  MobileNumbersWorkflowForm_UpsertPhoneNumberRequestWorkflowMutation,
  MobileNumbersWorkflowForm_UpsertPhoneNumberRequestWorkflowMutationVariables,
  OrgContactObjectType,
  PhoneNumberRequestSegmentTypeEnum,
} from "../../__generated__/graphql";
import SkeletonLoading from "../shared/SkeletonLoading";
import CiroErrorMsg from "../shared/forms/CiroErrorMsg";
import { MobileNumbersWorkflowsTable_Workflows } from "../mobileNumbers/MobileNumbersTable/MobileNumbersWorkflowsTable";
import { useNavigate } from "react-router-dom";
import CiroCreateableTextInput from "../shared/CiroCreateableTextInput";
import CiroCheckbox from "../shared/CiroCheckbox";

const SALESFORCE_SUPPORTED_FIELD_TYPES = new Set([
  "id",
  "boolean",
  // "reference",
  "string",
  "picklist",
  "textarea",
  // "double",
  "address",
  "phone",
  "email",
  // "date",
  // "datetime",
  "url",
  // "currency",
  // "multipicklist",
  "location",
]);

const HUBSPOT_SUPPORTED_FIELD_TYPES = new Set([
  "string",
  "number",
  "enumeration",
  // "datetime",
  // "date",
  "bool",
  // "object_coordinates",
  "phone_number",
]);

const PhoneNumberRequestWorkflowAttributesSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  segmentation: Yup.object().shape({
    segment_type: Yup.mixed()
      .oneOf(
        [
          PhoneNumberRequestSegmentTypeEnum.CallDisposition,
          PhoneNumberRequestSegmentTypeEnum.CrmRecordValues,
        ],
        "Segmentation type is required",
      )
      .required("Segmentation type is required"),
    call_dispositions: Yup.array()
      .of(Yup.string().required())
      .when("segment_type", {
        is: PhoneNumberRequestSegmentTypeEnum.CallDisposition,
        then: (schema) =>
          schema
            .min(1, "At least one call disposition is required")
            .required("Call dispositions are required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    crm_record_values: Yup.array()
      .of(Yup.string().nullable())
      .when("segment_type", {
        is: PhoneNumberRequestSegmentTypeEnum.CrmRecordValues,
        then: (schema) =>
          schema
            .min(1, "At least one CRM object value is required")
            .required("CRM object values are required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    crm_record_field: Yup.string().when("segment_type", {
      is: PhoneNumberRequestSegmentTypeEnum.CrmRecordValues,
      then: (schema) => schema.required("CRM object field is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    crm_record_field_type: Yup.string().when("segment_type", {
      is: PhoneNumberRequestSegmentTypeEnum.CrmRecordValues,
      then: (schema) => schema.required("CRM object field type is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    crm_record_type: Yup.string().when("segment_type", {
      is: PhoneNumberRequestSegmentTypeEnum.CrmRecordValues,
      then: (schema) => schema.required("CRM object type is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  }),
});

const MobileNumbersWorkflowForm_PhoneNumberRequestWorkflow = gql`
  query MobileNumbersWorkflowForm_PhoneNumberRequestWorkflow(
    $id: Int!
    $skipWorkflowLoad: Boolean!
  ) {
    phoneNumberRequestWorkflow(id: $id) @skip(if: $skipWorkflowLoad) {
      id
      name
      segmentation
    }
    organization {
      organizationMergeIntegration {
        integration
        orgContactObjectFields {
          crmRecordType
          fieldName
          fieldLabel
          fieldType
          fieldPicklist {
            value
            label
            active
          }
        }
        org_contact_object_type
        crmDispositions {
          id
          label
        }
      }
    }
  }
`;

const MobileNumbersWorkflowForm_UpsertPhoneNumberRequestWorkflow = gql`
  mutation MobileNumbersWorkflowForm_UpsertPhoneNumberRequestWorkflow(
    $input: UpsertPhoneNumberRequestWorkflowInput!
  ) {
    upsertPhoneNumberRequestWorkflow(input: $input) {
      success
      message
    }
  }
`;

interface IMobileNumbersWorkflowFormProps {
  workflowId: string | undefined;
}

const MobileNumbersWorkflowForm = ({
  workflowId,
}: IMobileNumbersWorkflowFormProps) => {
  const navigate = useNavigate();
  const {
    data: workflowData,
    loading: workflowLoading,
    error: workflowError,
  } = useQuery<
    MobileNumbersWorkflowForm_PhoneNumberRequestWorkflowQuery,
    MobileNumbersWorkflowForm_PhoneNumberRequestWorkflowQueryVariables
  >(MobileNumbersWorkflowForm_PhoneNumberRequestWorkflow, {
    variables: {
      id: Number(workflowId ? workflowId : "0"),
      skipWorkflowLoad: !workflowId,
    },
    onError: (error) => {
      setError("root", { message: error.message });
    },
  });

  const [upsertPhoneNumberRequestWorkflow, { loading: upsertLoading }] =
    useMutation<
      MobileNumbersWorkflowForm_UpsertPhoneNumberRequestWorkflowMutation,
      MobileNumbersWorkflowForm_UpsertPhoneNumberRequestWorkflowMutationVariables
    >(MobileNumbersWorkflowForm_UpsertPhoneNumberRequestWorkflow);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    trigger,
    setError,
  } = useForm({
    resolver: yupResolver(PhoneNumberRequestWorkflowAttributesSchema),
    defaultValues: {
      name: "",
      segmentation: {
        segment_type: null,
        call_dispositions: null,
        crm_record_values: null,
        crm_record_field: null,
        crm_record_field_type: null,
        crm_record_type: null,
      },
    } as {
      name: string;
      segmentation: {
        segment_type: PhoneNumberRequestSegmentTypeEnum | null;
        call_dispositions: string[] | null;
        crm_record_values: (string | null)[] | null;
        crm_record_field: string | null;
        crm_record_field_type: string | null;
        crm_record_type: string | null;
      };
    },
  });

  const segmentationTypeOptions = useMemo(() => {
    const options = [
      {
        label: "Call",
        value: PhoneNumberRequestSegmentTypeEnum.CallDisposition,
      },
    ];

    if (workflowData?.organization?.organizationMergeIntegration) {
      options.push({
        label: "Account, Contact, or Lead",
        value: PhoneNumberRequestSegmentTypeEnum.CrmRecordValues,
      });
    }

    return options;
  }, [workflowData]);

  const callDispositionOptions = useMemo(() => {
    if (!workflowData) {
      return [] as { label: string; value: string }[];
    }
    return (
      workflowData?.organization?.organizationMergeIntegration
        ?.crmDispositions || []
    )
      .map((disposition: CrmDisposition | null) => {
        if (!disposition) {
          return null;
        }
        return {
          label: disposition.label,
          value: disposition.id,
        };
      })
      .filter(Boolean) as { label: string; value: string }[];
  }, [workflowData]);

  const crmRecordFieldOptions = useMemo(() => {
    const isHubspot =
      workflowData?.organization?.organizationMergeIntegration?.integration ===
      IntegrationType.HubSpot;
    const isSalesforce =
      workflowData?.organization?.organizationMergeIntegration?.integration ===
      IntegrationType.Salesforce;
    if (!workflowData) {
      return [] as { label: string; value: string }[];
    }
    return (
      workflowData?.organization?.organizationMergeIntegration
        ?.orgContactObjectFields || []
    )
      .filter(
        (field) =>
          (isHubspot && HUBSPOT_SUPPORTED_FIELD_TYPES.has(field.fieldType)) ||
          (isSalesforce &&
            SALESFORCE_SUPPORTED_FIELD_TYPES.has(field.fieldType)),
      )
      .map((field) => {
        return {
          label: field.fieldLabel,
          value: field.fieldName,
        };
      });
  }, [workflowData]);

  const crmRecordFieldTypeMap = useMemo(() => {
    if (!workflowData) {
      return {} as { [key: string]: string };
    }

    return (
      workflowData?.organization?.organizationMergeIntegration
        ?.orgContactObjectFields || []
    ).reduce(
      (acc, field) => {
        if (!field.fieldPicklist || !field.fieldName) {
          return acc;
        }

        acc[field.fieldName] = field.fieldType;
        return acc;
      },
      {} as { [key: string]: string },
    );
  }, [workflowData]);

  const crmFieldValuePicklistOptionsMap = useMemo(() => {
    if (
      !workflowData?.organization?.organizationMergeIntegration
        ?.orgContactObjectFields
    ) {
      return {} as { [key: string]: { label: string; value: string }[] };
    }
    return workflowData?.organization?.organizationMergeIntegration?.orgContactObjectFields.reduce(
      (acc, field) => {
        if (
          !field.fieldPicklist ||
          !field.fieldName ||
          !SALESFORCE_SUPPORTED_FIELD_TYPES.has(field.fieldType)
        ) {
          return acc;
        }

        acc[field.fieldName] = field.fieldPicklist?.map((picklist) => {
          return {
            label: picklist.label || "",
            value: picklist.value || "",
          };
        });
        return acc;
      },
      {} as { [key: string]: { label: string; value: string }[] },
    );
  }, [workflowData]);

  const segmentationType = watch("segmentation.segment_type");
  const callDispositions = watch("segmentation.call_dispositions");
  const crmRecordField = watch("segmentation.crm_record_field");
  const crmRecordFieldValues = watch("segmentation.crm_record_values");
  watch("segmentation"); // needed for useEffect to trigger

  const onSubmit = useCallback(
    (data: any) => {
      // Separating this since the types from schema not matching with valid types that get through.
      data as {
        name: string;
        segmentation: {
          segment_type: PhoneNumberRequestSegmentTypeEnum;
          call_dispositions: string[] | null;
          crm_record_values: string[] | null;
          crm_record_field: string | null;
          crm_record_field_type: string | null;
          crm_record_type: string | null;
        };
      };

      const {
        segmentation: {
          call_dispositions,
          crm_record_values,
          crm_record_field,
          crm_record_field_type,
          crm_record_type,
          segment_type,
        },
      } = data;

      const formattedSegmentation = {
        callDisposition:
          segment_type === PhoneNumberRequestSegmentTypeEnum.CallDisposition
            ? {
                segment_type: segment_type,
                call_dispositions: call_dispositions,
              }
            : null,
        crmRecordValues:
          segment_type === PhoneNumberRequestSegmentTypeEnum.CrmRecordValues
            ? {
                segment_type,
                crm_record_type,
                crm_record_field,
                crm_record_field_type,
                crm_record_values,
              }
            : null,
      };

      upsertPhoneNumberRequestWorkflow({
        variables: {
          input: {
            id: workflowId ? Number(workflowId) : undefined,
            name: data.name,
            segmentation: formattedSegmentation,
          },
        },
        onCompleted: (_data) => {
          navigate(`/enrich-crm/workflows`);
        },
        onError: (_error) => {
          setError("root", {
            message: "Something went wrong. Please contact the Ciro Team",
          });
        },
        refetchQueries: [MobileNumbersWorkflowsTable_Workflows],
      });
    },
    [upsertPhoneNumberRequestWorkflow, workflowId, navigate, setError],
  );

  useEffect(() => {
    if (workflowData?.phoneNumberRequestWorkflow) {
      const segmentation = JSON.parse(
        workflowData?.phoneNumberRequestWorkflow?.segmentation || "{}",
      );

      setValue("name", workflowData?.phoneNumberRequestWorkflow?.name);
      setValue("segmentation", segmentation);
    } else if (workflowData?.organization?.organizationMergeIntegration) {
      setValue(
        "segmentation.crm_record_type",
        (workflowData.organization.organizationMergeIntegration
          .org_contact_object_type || null) as string | null,
      );
    }
  }, [workflowData, setValue]);

  const enrichmentNotFound =
    workflowId && workflowData && !workflowData?.phoneNumberRequestWorkflow;

  const usingBlankValues = useMemo(() => {
    if (!crmRecordFieldValues) {
      return false;
    }
    return crmRecordFieldValues?.findIndex((value) => value === null) !== -1;
  }, [crmRecordFieldValues]);

  return (
    <div
      className={classNames("ciro-v1-flex", "ciro-v1-w-full", "ciro-v1-pt-4")}
    >
      <div className={classNames("ciro-v1-w-2/5")}>
        <div className={classNames("ciro-v1-font-bold")}>Create workflow</div>
        <div className={classNames("ciro-v1-text-sm", "ciro-v1-text-gray-500")}>
          Define the contacts you would like to find and enrich.
        </div>
      </div>
      <div className={classNames("ciro-v1-w-3/5")}>
        <div
          className={classNames(
            "ciro-v1-w-full",
            "ciro-v1-bg-white",
            "ciro-v1-rounded-lg",
            "ciro-v1-p-8",
          )}
        >
          {workflowLoading && (
            <SkeletonLoading numSkeletons={2} skeletonHeight={"3rem"} />
          )}
          {!workflowLoading && (
            <>
              <div className={classNames("ciro-v1-mb-4")}>
                <CiroTextInput
                  label="Workflow name"
                  placeholder="Enter name"
                  {...register("name")}
                  error={
                    enrichmentNotFound
                      ? "Workflow not found"
                      : errors.name?.message
                  }
                />
              </div>
              <div className={classNames("ciro-v1-mb-4")}>
                <CiroDropDown
                  isMulti={false}
                  label="Enrichment source"
                  options={segmentationTypeOptions}
                  onChange={(value) => {
                    setValue("segmentation.segment_type", value);
                    trigger("segmentation.segment_type");
                  }}
                  value={segmentationType}
                  error={errors.segmentation?.segment_type?.message}
                  infoTooltip={
                    "Choose whether to enrich based on call data or by choosing a segment of accounts, contacts, or leads."
                  }
                />
              </div>
              {segmentationType ===
                PhoneNumberRequestSegmentTypeEnum.CallDisposition && (
                <div className={classNames("ciro-v1-mb-4")}>
                  <CiroDropDown
                    isMulti={true}
                    label="Call dispositions indicating a bad number"
                    options={callDispositionOptions}
                    value={callDispositions || []}
                    onChange={(value) => {
                      setValue("segmentation.call_dispositions", value);
                      trigger("segmentation.call_dispositions");
                    }}
                    infoTooltip={
                      "Choose the call outcomes that suggest a phone number needs updating. To add more dispositions to this list, edit your CRM settings."
                    }
                    error={errors.segmentation?.call_dispositions?.message}
                  />
                </div>
              )}
              {segmentationType ===
                PhoneNumberRequestSegmentTypeEnum.CrmRecordValues && (
                <>
                  <div className={classNames("ciro-v1-mb-4")}>
                    <CiroDropDown
                      isMulti={false}
                      label="CRM object type"
                      placeholder="CRM object type"
                      error={errors.segmentation?.crm_record_type?.message}
                      isDisabled={true}
                      value={
                        workflowData?.organization?.organizationMergeIntegration
                          ?.org_contact_object_type as string
                      }
                      options={[
                        {
                          label: "Account",
                          value: OrgContactObjectType.Account as string,
                        },
                        {
                          label: "Contact",
                          value: OrgContactObjectType.Contact as string,
                        },
                        {
                          label: "Lead",
                          value: OrgContactObjectType.Lead as string,
                        },
                      ]}
                      infoTooltip={
                        "Choose which CRM object will be used to identify records needing new numbers"
                      }
                    />
                  </div>
                  <div className={classNames("ciro-v1-mb-4")}>
                    <CiroDropDown
                      isMulti={false}
                      label="Field name"
                      placeholder="Field indicating the need for a new number"
                      value={crmRecordField}
                      error={errors.segmentation?.crm_record_field?.message}
                      onChange={(value) => {
                        setValue("segmentation.crm_record_field", value);
                        setValue("segmentation.crm_record_values", null);
                        setValue(
                          "segmentation.crm_record_field_type",
                          crmRecordFieldTypeMap[value],
                        );
                        trigger("segmentation.crm_record_field");
                      }}
                      options={crmRecordFieldOptions}
                      infoTooltip={
                        "Choose the field that indicates the need for a new number"
                      }
                    />
                  </div>
                  {crmRecordField && (
                    <div className={classNames("ciro-v1-mb-4")}>
                      {(crmFieldValuePicklistOptionsMap[crmRecordField]
                        ?.length || 0) > 0 ? (
                        <CiroDropDown
                          isMulti
                          label="Field values"
                          placeholder="Enter values indicating need for a new number"
                          infoTooltip={
                            "Choose the values that indicate the need for a new number"
                          }
                          error={
                            errors.segmentation?.crm_record_values?.message
                          }
                          options={
                            crmFieldValuePicklistOptionsMap[crmRecordField]
                          }
                          value={crmRecordFieldValues || []}
                          onChange={(value) => {
                            const newValues = [...value] as (string | null)[];
                            if (usingBlankValues) {
                              newValues.push(null);
                            }
                            setValue(
                              "segmentation.crm_record_values",
                              newValues,
                            );
                            trigger("segmentation.crm_record_values");
                          }}
                        />
                      ) : (
                        <CiroCreateableTextInput
                          containerWidthClass="ciro-v1-w-full"
                          label="Field values"
                          placeholder="Enter values indicating a need for a new number"
                          value={
                            (crmRecordFieldValues || []).filter(
                              Boolean,
                            ) as string[]
                          }
                          setValue={(value) => {
                            const newValues = [...value] as (string | null)[];
                            if (usingBlankValues) {
                              newValues.push(null);
                            }
                            setValue(
                              "segmentation.crm_record_values",
                              newValues,
                            );
                            trigger("segmentation.crm_record_values");
                          }}
                          error={
                            errors.segmentation?.crm_record_values?.message
                          }
                        />
                      )}
                      <div className={classNames("ciro-v1-mt-2")}>
                        <CiroCheckbox
                          label="Blank / null value should trigger a new number request"
                          checked={usingBlankValues}
                          onClick={() => {
                            if (!usingBlankValues) {
                              setValue("segmentation.crm_record_values", [
                                ...(crmRecordFieldValues || []),
                                null,
                              ]);
                            } else {
                              setValue(
                                "segmentation.crm_record_values",
                                (crmRecordFieldValues || []).filter(
                                  (value) => value !== null,
                                ),
                              );
                            }
                            trigger("segmentation.crm_record_values");
                          }}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              <div
                className={classNames(
                  "ciro-v1-w-full",
                  "ciro-v1-flex",
                  "ciro-v1-justify-end",
                  "ciro-v1-mt-6",
                )}
              >
                <CiroButton
                  style={CiroButtonStyleEnum.LOUD}
                  analyticsField="upsert-workflow-changes"
                  onClick={handleSubmit(onSubmit)}
                  disabled={upsertLoading || workflowLoading}
                >
                  {workflowId ? "Save" : "Create"}
                </CiroButton>
              </div>
              {errors.root?.message && (
                <CiroErrorMsg error={errors.root.message} />
              )}
              {workflowError && (
                <CiroErrorMsg
                  error={"Something went wrong. Please contact the Ciro Team"}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileNumbersWorkflowForm;
