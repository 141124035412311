import classNames from "classnames";
import CiroCard from "../shared/CiroCard";

const MobileNumbersOverviewCard = ({
  amount,
  description,
}: {
  amount: number;
  description: string;
}) => {
  return (
    <CiroCard size="small" customClassName={classNames("ciro-v1-w-full")}>
      <div
        className={classNames(
          "ciro-v1-text-2xl",
          "ciro-v1-font-bold",
          "ciro-v1-pb-4",
        )}
      >
        {amount}
      </div>
      <div className={classNames("ciro-v1-text-sm", "ciro-v1-text-zinc-500")}>
        {description}
      </div>
    </CiroCard>
  );
};

export default MobileNumbersOverviewCard;
