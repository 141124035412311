import { useEffect, useState, useRef } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import toast from "react-hot-toast";

import CiroBreadCrumbs from "../../components/shared/CiroBreadCrumbs";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroButton, {
  CiroButtonStyleEnum,
} from "../../components/shared/CiroButton";
import CiroModal from "../../components/shared/CiroModal";
import LinkSlashIcon from "../../assets/img/icons/LinkSlashIcon";
import CiroAlert from "../../components/shared/CiroAlert";

import {
  CrmMobileConnection_UserAccountQuery,
  CrmMobileConnection_UserAccountQueryVariables,
  OrgContactObjectType,
  RemoveOrganizationMergeIntegrationMutation,
  RemoveOrganizationMergeIntegrationMutationVariables,
} from "../../__generated__/graphql";
import CrmConnectionOverrideForm from "../../components/mobileNumbers/CrmConnectionSettings/CrmConnectionOverrideForm";
import SkeletonLoading from "../../components/shared/SkeletonLoading";
import DispositionSettingsForm from "../../components/mobileNumbers/CrmConnectionSettings/DispositionSettingsForm";
import CrmConnectionSettingsForm from "../../components/mobileNumbers/CrmConnectionSettings/CrmConnectionSettingsForm";

const CrmMobileConnection_UserAccount = gql`
  query CrmMobileConnection_UserAccount {
    userAccount {
      org {
        id
        organizationMergeIntegration {
          id
          integration
          org_contact_object_type
          invalid_number_statuses
          phone_number_status_field
          valid_numbers_statuses
          crmDispositions {
            id
            label
          }
          overwrite
          timestamp_field
          default_phone_write_field
        }
      }
    }
  }
`;

const removeOrganizationMergeIntegration = gql`
  mutation RemoveOrganizationMergeIntegration {
    updateOrganizationMergeIntegration(
      input: {
        org_contact_object_type: null
        phone_number_status_field: null
        invalid_number_statuses: null
        valid_numbers_statuses: null
        overwrite: "NO"
        timestamp_field: null
        default_phone_write_field: null
      }
    ) {
      id
    }
  }
`;

const CrmMobileConnection = () => {
  const navigate = useNavigate();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [crmObjectType, setCrmObjectType] =
    useState<OrgContactObjectType | null>(null);

  const { data, loading, error, refetch } = useQuery<
    CrmMobileConnection_UserAccountQuery,
    CrmMobileConnection_UserAccountQueryVariables
  >(CrmMobileConnection_UserAccount, {
    fetchPolicy: "network-only",
  });

  const [removeIntegration, { loading: removeLoading }] = useMutation<
    RemoveOrganizationMergeIntegrationMutation,
    RemoveOrganizationMergeIntegrationMutationVariables
  >(removeOrganizationMergeIntegration, {
    onCompleted: () => {
      toast.success("CRM connection removed successfully");
      navigate("/enrich-crm");
    },
    onError: (error) => {
      toast.error(`Error removing CRM connection: ${error.message}`);
    },
  });
  const crmSettingsRef = useRef<{
    onSubmit: () => void;
    checkValidity: () => Promise<boolean>;
  } | null>(null);
  const dispositionSettingsRef = useRef<{
    onSubmit: () => void;
    checkValidity: () => Promise<boolean>;
  } | null>(null);
  const crmOverrideRef = useRef<{
    onSubmit: () => void;
    checkValidity: () => Promise<boolean>;
  } | null>(null);

  const handleFinalizeSetup = async () => {
    if (crmSettingsRef.current && crmOverrideRef.current && dispositionSettingsRef.current) {
      const validSettings = await crmSettingsRef.current.checkValidity();
      const validOverride = await crmOverrideRef.current.checkValidity();
      const validDisposition = await dispositionSettingsRef.current.checkValidity();
      if (validSettings && validOverride && validDisposition) {
        crmSettingsRef.current.onSubmit();
        crmOverrideRef.current.onSubmit();
        dispositionSettingsRef.current.onSubmit();
      }
    }
  };

  const handleRemoveIntegration = async () => {
    await removeIntegration();
  };

  useEffect(() => {
    if (
      data?.userAccount?.org?.organizationMergeIntegration
        ?.org_contact_object_type
    ) {
      setCrmObjectType(
        data.userAccount.org.organizationMergeIntegration
          .org_contact_object_type,
      );
    } else {
      setCrmObjectType(
        data?.userAccount?.org?.organizationMergeIntegration?.integration ===
          "HubSpot"
          ? OrgContactObjectType.Contact
          : OrgContactObjectType.Lead,
      );
    }
  }, [data]);

  if (loading) {
    return (
      <div
        className={classNames(
          "ciro-v1-w-full",
          "ciro-v1-px-4",
          "ciro-v1-pt-16",
        )}
      >
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-flex-row",
            "ciro-v1-justify-between",
            "ciro-v1-gap-4",
            "ciro-v1-w-full",
          )}
        >
          <div className={classNames("ciro-v1-w-full")}>
            <SkeletonLoading numSkeletons={1} skeletonHeight={"6rem"} />
          </div>
          <div className={classNames("ciro-v1-w-full")}>
            <SkeletonLoading numSkeletons={1} skeletonHeight={"20rem"} />
          </div>
        </div>
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-flex-row",
            "ciro-v1-justify-between",
            "ciro-v1-gap-4",
            "ciro-v1-mt-4",
            "ciro-v1-w-full",
          )}
        >
          <div className={classNames("ciro-v1-w-full")}>
            <SkeletonLoading numSkeletons={1} skeletonHeight={"6rem"} />
          </div>
          <div className={classNames("ciro-v1-w-full")}>
            <SkeletonLoading numSkeletons={1} skeletonHeight={"20rem"} />
          </div>
        </div>
      </div>
    );
  }
  if (error) return <CiroAlert message={error.message} />;

  const integrationName =
    data?.userAccount?.org?.organizationMergeIntegration?.integration || "";
  const isInitialSetup =
    data?.userAccount?.org?.organizationMergeIntegration
      ?.invalid_number_statuses === null;

  return (
    <CiroContainer className={classNames("ciro-v1-bg-zinc-100")}>
      <div className={classNames("ciro-v1-w-full")}>
        <CiroBreadCrumbs
          values={["Enrich CRM", "CRM connection"]}
          href="/enrich-crm"
        />
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-justify-between",
            "ciro-v1-items-center",
          )}
        >
          {isInitialSetup ? (
            <h1 className={classNames("ciro-v1-text-2xl", "ciro-v1-pb-6")}>
              Set up your {integrationName} connection
            </h1>
          ) : (
            <h1 className={classNames("ciro-v1-text-2xl", "ciro-v1-pb-6")}>
              Manage {integrationName} connection
            </h1>
          )}
          <div className={classNames("ciro-v1-flex", "ciro-v1-gap-4")}>
            {!isInitialSetup && (
              <CiroButton
                analyticsField="remove_salesforce_connection"
                onClick={() => setShowRemoveModal(true)}
              >
                <LinkSlashIcon />
                <span className={classNames("ciro-v1-ml-2")}>
                  Remove {integrationName} connection
                </span>
              </CiroButton>
            )}
            {isInitialSetup && (
              <CiroButton
                analyticsField="finalize_crm_integration_setup"
                onClick={handleFinalizeSetup}
                disabled={removeLoading}
                style={CiroButtonStyleEnum.LOUD}
              >
                Finalize Setup
              </CiroButton>
            )}
          </div>
        </div>
        {data && (
          <div className={classNames("ciro-v1-space-y-8")}>
            <div className={classNames("ciro-v1-flex", "ciro-v1-gap-6")}>
              <div className={classNames("ciro-v1-w-1/3")}>
                <h1
                  className={classNames(
                    "ciro-v1-text-neutral-900",
                    "ciro-v1-text-base",
                    "ciro-v1-font-bold",
                    "ciro-v1-mb-2",
                  )}
                >
                  {integrationName} connection settings
                </h1>
                <h2
                  className={classNames(
                    "ciro-v1-text-neutral-500",
                    "ciro-v1-text-sm",
                    "ciro-v1-font-normal",
                  )}
                >
                  Define which object in {integrationName} will be enriched by
                  Ciro.
                </h2>
              </div>
              <div
                className={classNames(
                  "ciro-v1-bg-white",
                  "ciro-v1-p-4",
                  "ciro-v1-rounded-lg",
                  "ciro-v1-border",
                  "ciro-v1-border-neutral-200",
                  "ciro-v1-w-2/3",
                )}
              >
                {data.userAccount && (
                  <CrmConnectionSettingsForm
                    initialData={data.userAccount}
                    refetch={refetch}
                    setCrmObjectType={setCrmObjectType}
                    isInitialSetup={isInitialSetup}
                    ref={crmSettingsRef}
                  />
                )}
              </div>
            </div>
            <div className={classNames("ciro-v1-flex", "ciro-v1-gap-6")}>
              <div className={classNames("ciro-v1-w-1/3")}>
                <h1
                  className={classNames(
                    "ciro-v1-text-neutral-900",
                    "ciro-v1-text-base",
                    "ciro-v1-font-bold",
                    "ciro-v1-mb-2",
                  )}
                >
                  Call metrics
                </h1>
                <h2
                  className={classNames(
                    "ciro-v1-text-neutral-500",
                    "ciro-v1-text-sm",
                    "ciro-v1-font-normal",
                  )}
                >
                  Track the success of calls made to numbers enriched by Ciro.
                </h2>
              </div>
              <div
                className={classNames(
                  "ciro-v1-bg-white",
                  "ciro-v1-p-4",
                  "ciro-v1-rounded-lg",
                  "ciro-v1-border",
                  "ciro-v1-border-neutral-200",
                  "ciro-v1-w-2/3",
                )}
              >
                {data.userAccount && (
                  <DispositionSettingsForm
                    initialData={data.userAccount}
                    refetch={refetch}
                    setCrmObjectType={setCrmObjectType}
                    isInitialSetup={isInitialSetup}
                    ref={dispositionSettingsRef}
                  />
                )}
              </div>
            </div>

            <div className={classNames("ciro-v1-flex", "ciro-v1-gap-6")}>
              <div className={classNames("ciro-v1-w-1/3")}>
                <h1
                  className={classNames(
                    "ciro-v1-text-neutral-900",
                    "ciro-v1-text-base",
                    "ciro-v1-font-bold",
                    "ciro-v1-mb-2",
                  )}
                >
                  Writes to {integrationName}
                </h1>
                <h2
                  className={classNames(
                    "ciro-v1-text-neutral-500",
                    "ciro-v1-text-sm",
                    "ciro-v1-font-normal",
                  )}
                >
                  Configure how Ciro updates numbers in {integrationName}.
                </h2>
              </div>
              <div
                className={classNames(
                  "ciro-v1-bg-white",
                  "ciro-v1-p-4",
                  "ciro-v1-rounded-lg",
                  "ciro-v1-border",
                  "ciro-v1-border-neutral-200",
                  "ciro-v1-w-2/3",
                )}
              >
                {data.userAccount && (
                  <CrmConnectionOverrideForm
                    initialData={data.userAccount}
                    refetch={refetch}
                    crmObjectType={crmObjectType}
                    ref={crmOverrideRef}
                    isInitialSetup={isInitialSetup}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <CiroModal
        isOpen={showRemoveModal}
        onClose={() => setShowRemoveModal(false)}
      >
        <div className="ciro-v1-mb-6">
          <h2 className="ciro-v1-text-xl ciro-v1-font-semibold ciro-v1-mb-2">
            Remove {integrationName} Connection
          </h2>
          <p className="ciro-v1-mb-2">
            Are you sure you want to remove the {integrationName} connection?
          </p>
          <p className="ciro-v1-text-red-600">This action cannot be undone.</p>
        </div>
        <div className="ciro-v1-flex ciro-v1-justify-end ciro-v1-gap-4">
          <CiroButton
            analyticsField="cancel_remove_salesforce_connection"
            onClick={() => setShowRemoveModal(false)}
          >
            Cancel
          </CiroButton>
          <CiroButton
            analyticsField="remove_salesforce_connection"
            onClick={handleRemoveIntegration}
            style={CiroButtonStyleEnum.DELETE}
            disabled={removeLoading}
          >
            Remove
          </CiroButton>
        </div>
      </CiroModal>
    </CiroContainer>
  );
};

export default CrmMobileConnection;
