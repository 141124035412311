import classNames from "classnames";
import ApolloIcon from "../../../assets/img/icons/ApolloIcon";
import MobileNumbersIntegrationOption from "./MobileNumbersIntegrationOption";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import SalesforceIcon from "../../../assets/img/icons/SalesforceIcon";
import { useMergeLink } from "@mergeapi/react-merge-link";
import { gql } from "@apollo/client";
import { useEffect, useRef } from "react";
import { MobileNumbersGetStarted_UserAccountFragment } from "../../../__generated__/graphql";
import HubspotIcon from "../../../assets/img/icons/HubspotIcon";

interface IMobileNumbersGetStartedProps {
  onClose: () => void;
  userAccount: MobileNumbersGetStarted_UserAccountFragment;
}

export const MobileNumbersGetStarted_UserAccount = gql`
  fragment MobileNumbersGetStarted_UserAccount on UserAccount {
    id
    mergeLinkToken
    hasCrmIntegrationEnabled
    org {
      organizationMergeIntegration {
        integration
        last_matched_at
      }
    }
  }
`;

const MobileNumbersGetStarted = ({
  onClose,
  userAccount,
}: IMobileNumbersGetStartedProps) => {
  const navigate = useNavigate();
  const initialPropValue = useRef(userAccount?.mergeLinkToken);

  useEffect(() => {
    if (initialPropValue.current == null) {
      initialPropValue.current = userAccount?.mergeLinkToken;
    }
  }, [userAccount?.mergeLinkToken]);

  const onSuccess = () => {
    navigate("/enrich-crm/crm-integration");
  };

  const { open } = useMergeLink({
    linkToken: String(initialPropValue.current),
    onSuccess,
  });
  return (
    <div>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-justify-between",
          "ciro-v1-items-center",
        )}
      >
        <div
          className={classNames(
            "ciro-v1-text-xl",
            "ciro-v1-font-bold",
            "ciro-v1-pb-2",
            "ciro-v1-text-neutral-900",
          )}
        >
          Enrich Phone Data via Ciro Numbers
        </div>
        <div>
          <CiroButton
            style={CiroButtonStyleEnum.UNSTYLED}
            analyticsField="Close Apollo Integration Setup Modal"
            onClick={() => onClose()}
          >
            <XMarkIcon className={classNames("ciro-v1-h-6", "ciro-v1-w-6")} />
          </CiroButton>
        </div>
      </div>
      <div
        className={classNames("ciro-v1-text-md", "ciro-v1-text-neutral-900")}
      >
        Ciro pulls from all of the world's best mobile phone data providers.
        Connect to Ciro and get access to ALL of them.
      </div>
      <div
        className={classNames(
          "ciro-v1-text-md",
          "ciro-v1-font-bold",
          "ciro-v1-text-neutral-600",
          "ciro-v1-mt-4",
        )}
      >
        Get Started
      </div>
      <MobileNumbersIntegrationOption
        icon={<ApolloIcon />}
        title="Continue with Apollo"
        onClick={() => navigate("/enrich-crm/apollo-integration")}
      />
      {userAccount?.org?.organizationMergeIntegration?.integration !==
        "HubSpot" && (
        <MobileNumbersIntegrationOption
          icon={<SalesforceIcon />}
          title="Continue with Salesforce"
          onClick={async () => {
            if (userAccount?.hasCrmIntegrationEnabled) {
              navigate("/enrich-crm/crm-integration");
            } else {
              open();
            }
          }}
        />
      )}
      {userAccount?.org?.organizationMergeIntegration?.integration !==
        "Salesforce" && (
        <MobileNumbersIntegrationOption
          icon={<HubspotIcon />}
          title="Continue with Hubspot"
          onClick={async () => {
            if (userAccount?.hasCrmIntegrationEnabled) {
              navigate("/enrich-crm/crm-integration");
            } else {
              open();
            }
          }}
        />
      )}
    </div>
  );
};

export default MobileNumbersGetStarted;
